<div class="container123">
<div class="container  ">
    <div class="row  ">
        <div class="col-md-10">
          
        </div>
        <div class="col-md-2">
            <a routerLink="/"><img src="./../../assets/image/Component 7 – 18.svg" class="tilee" alt="..."></a> 
        </div>
    </div>
    <div class="row allsec">

        <div class="col-md-8 pt-5 line ">
            <h1 class="text-white display-4 text_c py-1 mobile_tex">Shy Girls Talks "AWZ" Mixtape,touring with Haim, and protland
                Life</h1>
            <p class="text-white display-4 py-1">03.06.2015</p>
            <p class="text-white name pb-5">Music</p>

        </div>
        <div class="col-xs-2">
        </div>
        <div class="col-md-2 mt-5 pt-3   float-right box">
            <h4 class="text-white share ">Author</h4>
            <div class="row pt-3">

                <div class="col-md-12">
                    <img class="img-responsive" src="./../../assets/image/bloguser3.jpg">
                    <h1 class="text-white name pt-3">Kai Acevedo</h1>


                </div>



            </div>
            <div class="row py-3">

                <div class="col-md-12 ">
                    <img class="micon  " src="./../../assets/image/Group 372.svg">&nbsp;
                    <img class="micon" src="./../../assets/image/Group 371.svg">&nbsp;
                    <img class="micon" src="./../../assets/image/Group 370.svg">&nbsp;
                    <img class="micon" src="./../../assets/image/Group 425.svg">&nbsp;
                    <img class="micon" src="./../../assets/image/Group 369.svg">&nbsp;
                </div>
              
            </div>

        </div>

    </div>

    <div class="row">

        <div class="col-md-8 py-5  ">
            <h1 class="text-white  text_h pb-5 mobile_tex">We're Proud to Welcome Wilson Chandler to Roc Nation Sports!</h1>
            <img class="image pb-2" src="./../../assets/image/bloguser3.jpg">
            <p class="text-white name"> Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor
                incididunt ut labore
                et dolore magna aliqua. Ut enim ad minim veniam, quis nostrud exercitation ullamco laboris nisi ut
                aliquip ex ea commodo consequat. Duis aute irure dolor in reprehenderit in voluptate velit esse
                cillum dolore eu fugiat nulla pariatur. Excepteur sint occaecat cupidatat non proident, sunt in
                culpa qui officia deserunt mollit anim id est laborum.</p>
            <p class="text-white name"> Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor
                incididunt ut labore
                et dolore magna aliqua. Ut enim ad minim veniam, quis nostrud exercitation ullamco laboris nisi ut
                aliquip ex ea commodo consequat. Duis aute irure dolor in reprehenderit in voluptate velit esse
                cillum dolore eu fugiat nulla pariatur.</p>
            <p class="text-white name line  pb-5"> Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor
                incididunt ut labore
                et dolore magna aliqua. Ut enim ad minim veniam, quis nostrud exercitation ullamco laboris nisi ut
                aliquip ex ea commodo consequat. Duis aute irure dolor in reprehenderit in voluptate velit esse
                cillum dolore eu fugiat nulla pariatur. </p>
                <div class="row">

                    <div class="col-md-2 pt-5   ">
                        <h4 class="text-white float-left ">   <img class="img-responsive" src="./../../assets/image/bloguser3.jpg"></h4>
                      
                    </div>
                    <div class="col-md-8  pt-5   ">
                      
                        <h1 class="text-white name">Kai Acevedo</h1>
                        <h4  class=" f_text">5 min ago</h4>
                    

                    </div>
                    <div class="col-md-2  pt-5   ">
                        <h4 class="text-white float-right "><img class="icon" src="./../../assets/image/Group 440 (1).svg"></h4>
                    </div>
            
                    
                  
                </div>
                <div class="row ">

                    <div class="col-md-12 pt-5 mob_p  ">
                        <h1 class=" name f_text tought">What Are You Thoughts?</h1>
                      
                      </div>
                   
                </div>
                <div class="row line pb-5 ">

                    <div class="col-md-12 pt-3 ">
                      
                        <button type="button" class="btn btn-outline-light p-2">Respond</button>
                      </div>
                   
                </div>
                <div class="row">

                    <div class="col-md-2 pt-5  ">
                        <h4 class="text-white float-left ">   <img class="img-responsive" src="./../../assets/image/bloguser3.jpg"></h4>
                      
                    </div>
                    <div class="col-md-8  pt-5   ">
                      
                        <h1 class="text-white name">Kai Acevedo</h1>
                        <h4  class=" f_text">5 min ago</h4>
                    

                    </div>
                    <div class="col-md-2  pt-5   ">
                        <h4 class="text-white float-right "><img class="icon" src="./../../assets/image/Group 440 (1).svg"></h4>
                    </div>
            
                    
                  
                </div>
                <div class="row  ">

                    <div class="col-md-12 pt-5 mob_p   ">
                        <h1 class="text-white name   ">Lorem Ipsum is simply dummy text of the printing and typesetting industry. Lorem Ipsum has been the industry's standard dummy text ever since the 1500s, when an unknown printer took a galley of type and scrambled it to make a type specimen book.</h1>
                      
                      </div>
                   
                </div>
                <div class="row pt-5 line pb-5">

                    <div class="col-md-2">
    
                        <h4 class="text-white float-left "> <img class="claps" src="./../../assets/image/Group 437.svg"></h4>
                        <span class="text-white responce ">&nbsp;&nbsp;9</span>
    
                    </div>
                  
                    <div class="col-md-10">
    
                        <img class="claps" src="./../../assets/image/Group 432.svg">
                        <div class="row pt-5 ">

                            <div class="col-md-1 pt-2">
                                <h4 class="text-white float-left ">   <img class="img-responsive" src="./../../assets/image/bloguser3.jpg"></h4>
                               
                            </div>
                            <div class="col-md-11">
                              
                                <h1 class="text-white name mb-0">Kai Acevedo</h1>
                                <h4  class=" f_text">08-12-2020</h4>
                                <p class="text-white name">Lorem Ipsum dolor sit amet consectetur sadipscing elitr.</p>
                            </div>
                         
                        </div>
                        
    
                    </div>
    
                </div>
              
                <div class="row">

                    <div class="col-md-2 pt-5   ">
                        <h4 class="text-white float-left ">   <img class="img-responsive" src="./../../assets/image/bloguser3.jpg"></h4>
                      
                    </div>
                    <div class="col-md-8  pt-5 mob_p  ">
                      
                        <h1 class="text-white name">Kai Acevedo</h1>
                        <h4  class=" f_text">08-12-2020</h4>
                    

                    </div>
                    <div class="col-md-2  pt-5  mob_p  ">
                        <h4 class="text-white float-right "><img class="icon" src="./../../assets/image/Group 440 (1).svg"></h4>
                    </div>
            
                    
                  
                </div>
                <div class="row ">

                    <div class="col-md-12 pt-5  ">
                        <h1 class="text-white name mob_p">Lorem Ipsum is simply dummy text of the printing and typesetting industry. Lorem Ipsum has been the industry's standard dummy text ever since the 1500s, when an unknown printer took a galley of type and scrambled it to make a type specimen book.</h1>
                      
                      </div>
                   
                </div>
                <div class="row pt-5">

                    <div class="col-md-2">
    
                        <h4 class="text-white float-left "> <img class="claps" src="./../../assets/image/Group 437.svg"></h4>
                        <span class="text-white responce ">&nbsp;&nbsp;9</span>
    
                    </div>
                    <div class="col-md-10">
    
                        <img class="claps" src="./../../assets/image/Group 432.svg">
                        <div class="row pt-5 ">

                            <div class="col-md-1">
                                <h4 class="text-white float-left ">   <img class="img-responsive" src="./../../assets/image/bloguser3.jpg"></h4>
                               
                            </div>
                            <div class="col-md-11 pt-1">
                              
                                <p class="text-white name"> Add a rply...</p>
                              
                            </div>
                         
                        </div>
                        
    
                    </div>
    
                </div>
                <div class="row  ">

                    <div class="col-md-12 related  ">
                        <hr class="line1">
                        <h1 class="f_text py-4 related">Related Blogs</h1>
                        <div class="card-deck">
                          
                            <div class="card">
                                <div class="mb-3 pics animation all 2 img__wrap">
                                    <img src="./../../assets/image/bloguser4.jpg" class="card-img-top" alt="...">
                                    <h3 class="img__description p-4 ">Nina Las Vegas: Australia’s Triple Threat<p>03.12.2015</p></h3>
                                 
                                  
                                </div>
                            </div>
                            <div class="card">
                                <div class="mb-3 pics animation all 2 img__wrap h-100">
                                    <img src="./../../assets/image/bloguser4.jpg" class="card-img-top" alt="...">
                                    <h3 class="img__description p-4 ">Nina Las Vegas: Australia’s Triple Threat<p>03.12.2015</p></h3>
                                 
                                  
                                </div>
                              </div>
                            <div class="card">
                                <div class="mb-3 pics animation all 2 img__wrap h-100">
                                    <img src="./../../assets/image/bloguser4.jpg" class="card-img-top" alt="...">
                                    <h3 class="img__description p-4 ">Nina Las Vegas: Australia’s Triple Threat<p>03.12.2015</p></h3>
                                 
                                  
                                </div>
                              </div>
                              <div class="card">
                                <div class="mb-3 pics animation all 2 img__wrap h-100">
                                    <img src="./../../assets/image/bloguser4.jpg" class="card-img-top" alt="...">
                                    <h3 class="img__description p-4 ">Nina Las Vegas: Australia’s Triple Threat<p>03.12.2015</p><p class="sometext1">Sometimes you never ometimes you never
                                       .</p></h3>
                                 
                                  
                                </div>
                              </div>
                          </div>
                      
                      </div>
                   
               
            </div>
        </div>
     

        
        <div class="col-xs-1">
        </div>
        <div class="col-md-2 mt-5 pt-3   float-right box1">
            <div class="row">
                <div class="col-md-12">
                    <h4 class="text-white float-left share ">Share</h4>
                    <h4 class="text-white float-right "><i class="fa fa-ellipsis-h fa-1x " ></i></h4>

                </div>
            </div>
            <div class="row py-2 ">

                <div class="col-md-12">
                    <img class="micon  " src="./../../assets/image/Group 372.svg">&nbsp;
                    <img class="micon" src="./../../assets/image/Group 371.svg">&nbsp;
                    <img class="micon" src="./../../assets/image/Group 370.svg">&nbsp;
                    <img class="micon" src="./../../assets/image/Group 425.svg">&nbsp;
                    <img class="micon" src="./../../assets/image/Group 369.svg">&nbsp;

                </div>
              
                <hr class="hr1 mt-4">

            </div>
            <h1 class="text-white music py-2">Music protLand<br> Shy Girls <br>The Weekend <br>Talks </h1>
           <ul class="iconmob">
             <li> <h4 class="text-white float-left "> <img class="claps" src="./../../assets/image/Group 437.svg"></h4></li>  
             <li> <h4 class="text-white clapp  ">1,173 claps</h4></li>
             <li>   <h4 class="text-white float-left "> <img class="claps" src="./../../assets/image/Group 432.svg"></h4></li>  
             <li> <h4 class="text-white clapp  ">1,173 claps</h4></li>
             <li>  <h4 class="text-white float-left "> <img class="claps" src="./../../assets/image/Group 434.svg"></h4></li>  
             <li> <h4 class="text-white clapp  ">1,173 claps</h4></li>
           </ul>
           
           
           
            
        </div>
        <div class="col-xs-1">
        </div>
    </div>

 
  
</div>
</div>

<div class="container cc-container  ">
   
    <div class="row">
        <div class="col-md-3"></div>
        <div class="col-md-1">
            <a routerLink="/"> <h2 class="text-white mob_t ">Parable <br> Symptoms</h2></a>
        </div>
        <div class="col-md-3 text-white">
            <h2 class=" display-4 mob_t">
                0&nbsp;2&nbsp;.&nbsp;0&nbsp;2&nbsp;.&nbsp;2&nbsp;0&nbsp;2&nbsp;0</h2>
        </div>
        <div class="col-md-2 text-white">
          <h2 class=" display-4 mob_t">
              0&nbsp;2&nbsp;.&nbsp;0&nbsp;2&nbsp;.&nbsp;2&nbsp;0&nbsp;2&nbsp;0</h2>
      </div>
        <div class="col-md-3"></div>
    </div>
    <div class="row">
        <div class="col-md-12">
            <h5 class="text-white text-center">&copy; 2020 Parable Symptom. All right reserved Terms of use privacy
                policy</h5>
        </div>

    </div>
</div>