<div class="container123">
    <div class="container   ">
        <div class="row">
         
           
            <div class="col-md-12">
                <a routerLink="/"> <img src="./../../assets/image/Component 7 – 18.svg" class="tilee  float-right" alt="..."></a>
            </div>
        </div><br><br>
        <div class="row  ">
            <div class="col-md-12">
                <a routerLink="/create_blog">  <h1 class=" list active  "><img src="./../../assets/image/Group 355.svg"
                    class="arrow pl-3 float-left" alt="...">&nbsp;&nbsp;Back </h1></a>
    
            </div>
           
          
        </div>
       
       
        <div class="container">
            <div class="card-deck">
                <div class="card mb-4">
                    <a routerLink="/author-profile">
                    <img src="./../../assets/image/bloguser4.jpg" class="card-img-top" alt="...">
                    <div class="card-body">
                        <h3 class="card-title text-white pb-3">Colin Delgado</h3>
                        <img class="micon image " src="./../../assets/image/Group 372.svg">&nbsp;&nbsp;
                        <img class="micon" src="./../../assets/image/Group 371.svg">&nbsp;&nbsp;
                        <img class="micon" src="./../../assets/image/Group 370.svg">&nbsp;&nbsp;
                        <img class="micon" src="./../../assets/image/Group 425.svg">&nbsp;&nbsp;
                        <img class="micon" src="./../../assets/image/Group 369.svg">&nbsp;&nbsp;
                    </div>
                    <div class="overlay">
                    </div></a>
                </div>
                <div class="card mb-4">
                    <a routerLink="/author-profile">
                    <img src="./../../assets/image/bloguser3.jpg" class="card-img-top" alt="...">
                    <div class="card-body">
                        <h3 class="card-title text-white pb-3">Colin Delgado</h3>
                        <img class="micon image " src="./../../assets/image/Group 372.svg">&nbsp;&nbsp;
                        <img class="micon" src="./../../assets/image/Group 371.svg">&nbsp;&nbsp;
                        <img class="micon" src="./../../assets/image/Group 370.svg">&nbsp;&nbsp;
                        <img class="micon" src="./../../assets/image/Group 425.svg">&nbsp;&nbsp;
                        <img class="micon" src="./../../assets/image/Group 369.svg">&nbsp;&nbsp;
                    </div>
                    <div class="overlay">
                    </div></a>
                </div>
                <div class="w-100 d-none d-sm-block d-md-none"><!-- wrap every 2 on sm--></div>
                <div class="card mb-4">
                    <a routerLink="/author-profile">
                    <img src="./../../assets/image/bloguser3.jpg" class="card-img-top" alt="...">
                    <div class="card-body">
                        <h3 class="card-title text-white pb-3">Colin Delgado</h3>
                        <img class="micon image " src="./../../assets/image/Group 372.svg">&nbsp;&nbsp;
                        <img class="micon" src="./../../assets/image/Group 371.svg">&nbsp;&nbsp;
                        <img class="micon" src="./../../assets/image/Group 370.svg">&nbsp;&nbsp;
                        <img class="micon" src="./../../assets/image/Group 425.svg">&nbsp;&nbsp;
                        <img class="micon" src="./../../assets/image/Group 369.svg">&nbsp;&nbsp;
                    </div>
                    <div class="overlay">
                    </div></a>
                </div>
                <div class="w-100 d-none d-md-block d-lg-none"><!-- wrap every 3 on md--></div>
                <div class="card mb-4">
                    <a routerLink="/author-profile">
                    <img src="./../../assets/image/bloguser3.jpg" class="card-img-top" alt="...">
                    <div class="card-body">
                        <h3 class="card-title text-white pb-3">Colin Delgado</h3>
                        <img class="micon image " src="./../../assets/image/Group 372.svg">&nbsp;&nbsp;
                        <img class="micon" src="./../../assets/image/Group 371.svg">&nbsp;&nbsp;
                        <img class="micon" src="./../../assets/image/Group 370.svg">&nbsp;&nbsp;
                        <img class="micon" src="./../../assets/image/Group 425.svg">&nbsp;&nbsp;
                        <img class="micon" src="./../../assets/image/Group 369.svg">&nbsp;&nbsp;
                    </div>
                    <div class="overlay">
                    </div></a>
                </div>
                <div class="w-100 d-none d-sm-block d-md-none"><!-- wrap every 2 on sm--></div>
                 <div class="w-100 d-none d-lg-block d-xl-none"><!-- wrap every 4 on lg--></div>
                 <div class="card mb-4">
                    <a routerLink="/author-profile">
                    <img src="./../../assets/image/bloguser3.jpg" class="card-img-top" alt="...">
                    <div class="card-body">
                        <h3 class="card-title text-white pb-3">Colin Delgado</h3>
                        <img class="micon image " src="./../../assets/image/Group 372.svg">&nbsp;&nbsp;
                        <img class="micon" src="./../../assets/image/Group 371.svg">&nbsp;&nbsp;
                        <img class="micon" src="./../../assets/image/Group 370.svg">&nbsp;&nbsp;
                        <img class="micon" src="./../../assets/image/Group 425.svg">&nbsp;&nbsp;
                        <img class="micon" src="./../../assets/image/Group 369.svg">&nbsp;&nbsp;
                    </div>
                    <div class="overlay">
                    </div></a>
                </div>
                <div class="w-100 d-none d-xl-block"><!-- wrap every 5 on xl--></div>
                <div class="card mb-4">
                    <a routerLink="/author-profile">
                    <img src="./../../assets/image/bloguser3.jpg" class="card-img-top" alt="...">
                    <div class="card-body">
                        <h3 class="card-title text-white pb-3">Colin Delgado</h3>
                        <img class="micon image " src="./../../assets/image/Group 372.svg">&nbsp;&nbsp;
                        <img class="micon" src="./../../assets/image/Group 371.svg">&nbsp;&nbsp;
                        <img class="micon" src="./../../assets/image/Group 370.svg">&nbsp;&nbsp;
                        <img class="micon" src="./../../assets/image/Group 425.svg">&nbsp;&nbsp;
                        <img class="micon" src="./../../assets/image/Group 369.svg">&nbsp;&nbsp;
                    </div>
                    <div class="overlay">
                    </div></a>
                </div>
                <div class="w-100 d-none d-sm-block d-md-none"><!-- wrap every 2 on sm--></div>
                <div class="w-100 d-none d-md-block d-lg-none"><!-- wrap every 3 on md--></div>
                <div class="card mb-4">
                    <a routerLink="/author-profile">
                    <img src="./../../assets/image/bloguser3.jpg" class="card-img-top" alt="...">
                    <div class="card-body">
                        <h3 class="card-title text-white pb-3">Colin Delgado</h3>
                        <img class="micon image " src="./../../assets/image/Group 372.svg">&nbsp;&nbsp;
                        <img class="micon" src="./../../assets/image/Group 371.svg">&nbsp;&nbsp;
                        <img class="micon" src="./../../assets/image/Group 370.svg">&nbsp;&nbsp;
                        <img class="micon" src="./../../assets/image/Group 425.svg">&nbsp;&nbsp;
                        <img class="micon" src="./../../assets/image/Group 369.svg">&nbsp;&nbsp;
                    </div>
                    <div class="overlay">
                    </div></a>
                </div>
                <div class="card mb-4">
                    <a routerLink="/author-profile">
                    <img src="./../../assets/image/bloguser3.jpg" class="card-img-top" alt="...">
                    <div class="card-body">
                        <h3 class="card-title text-white pb-3">Colin Delgado</h3>
                        <img class="micon image " src="./../../assets/image/Group 372.svg">&nbsp;&nbsp;
                        <img class="micon" src="./../../assets/image/Group 371.svg">&nbsp;&nbsp;
                        <img class="micon" src="./../../assets/image/Group 370.svg">&nbsp;&nbsp;
                        <img class="micon" src="./../../assets/image/Group 425.svg">&nbsp;&nbsp;
                        <img class="micon" src="./../../assets/image/Group 369.svg">&nbsp;&nbsp;
                    </div>
                    <div class="overlay">
                    </div></a>
                </div>
                <div class="w-100 d-none d-sm-block d-md-none"><!-- wrap every 2 on sm--></div>
                <div class="w-100 d-none d-lg-block d-xl-none"><!-- wrap every 4 on lg--></div>
                <div class="card mb-4">
                    <a routerLink="/author-profile">
                    <img src="./../../assets/image/bloguser3.jpg" class="card-img-top" alt="...">
                    <div class="card-body">
                        <h3 class="card-title text-white pb-3">Colin Delgado</h3>
                        <img class="micon image " src="./../../assets/image/Group 372.svg">&nbsp;&nbsp;
                        <img class="micon" src="./../../assets/image/Group 371.svg">&nbsp;&nbsp;
                        <img class="micon" src="./../../assets/image/Group 370.svg">&nbsp;&nbsp;
                        <img class="micon" src="./../../assets/image/Group 425.svg">&nbsp;&nbsp;
                        <img class="micon" src="./../../assets/image/Group 369.svg">&nbsp;&nbsp;
                    </div>
                    <div class="overlay">
                    </div></a>
                </div>
                <div class="w-100 d-none d-md-block d-lg-none"><!-- wrap every 3 on md--></div>
                <div class="card mb-4">
                    <a routerLink="/author-profile">
                    <img src="./../../assets/image/bloguser3.jpg" class="card-img-top" alt="...">
                    <div class="card-body">
                        <h3 class="card-title text-white pb-3">Colin Delgado</h3>
                        <img class="micon image " src="./../../assets/image/Group 372.svg">&nbsp;&nbsp;
                        <img class="micon" src="./../../assets/image/Group 371.svg">&nbsp;&nbsp;
                        <img class="micon" src="./../../assets/image/Group 370.svg">&nbsp;&nbsp;
                        <img class="micon" src="./../../assets/image/Group 425.svg">&nbsp;&nbsp;
                        <img class="micon" src="./../../assets/image/Group 369.svg">&nbsp;&nbsp;
                    </div>
                    <div class="overlay">
                    </div></a>
                </div>
            </div>
             <div class="card-deck">
                <div class="card mb-4">
                    <a routerLink="/author-profile">
                    <img src="./../../assets/image/bloguser3.jpg" class="card-img-top" alt="...">
                    <div class="card-body">
                        <h3 class="card-title text-white pb-3">Colin Delgado</h3>
                        <img class="micon image " src="./../../assets/image/Group 372.svg">&nbsp;&nbsp;
                        <img class="micon" src="./../../assets/image/Group 371.svg">&nbsp;&nbsp;
                        <img class="micon" src="./../../assets/image/Group 370.svg">&nbsp;&nbsp;
                        <img class="micon" src="./../../assets/image/Group 425.svg">&nbsp;&nbsp;
                        <img class="micon" src="./../../assets/image/Group 369.svg">&nbsp;&nbsp;
                    </div>
                    <div class="overlay">
                    </div></a>
                </div>
                <div class="card mb-4">
                    <a routerLink="/author-profile">
                    <img src="./../../assets/image/bloguser3.jpg" class="card-img-top" alt="...">
                    <div class="card-body">
                        <h3 class="card-title text-white pb-3">Colin Delgado</h3>
                        <img class="micon image " src="./../../assets/image/Group 372.svg">&nbsp;&nbsp;
                        <img class="micon" src="./../../assets/image/Group 371.svg">&nbsp;&nbsp;
                        <img class="micon" src="./../../assets/image/Group 370.svg">&nbsp;&nbsp;
                        <img class="micon" src="./../../assets/image/Group 425.svg">&nbsp;&nbsp;
                        <img class="micon" src="./../../assets/image/Group 369.svg">&nbsp;&nbsp;
                    </div>
                    <div class="overlay">
                    </div></a>
                </div>
                <div class="w-100 d-none d-sm-block d-md-none"><!-- wrap every 2 on sm--></div>
                <div class="card mb-4">
                    <a routerLink="/author-profile">
                    <img src="./../../assets/image/bloguser3.jpg" class="card-img-top" alt="...">
                    <div class="card-body">
                        <h3 class="card-title text-white pb-3">Colin Delgado</h3>
                        <img class="micon image " src="./../../assets/image/Group 372.svg">&nbsp;&nbsp;
                        <img class="micon" src="./../../assets/image/Group 371.svg">&nbsp;&nbsp;
                        <img class="micon" src="./../../assets/image/Group 370.svg">&nbsp;&nbsp;
                        <img class="micon" src="./../../assets/image/Group 425.svg">&nbsp;&nbsp;
                        <img class="micon" src="./../../assets/image/Group 369.svg">&nbsp;&nbsp;
                    </div>
                    <div class="overlay">
                    </div></a>
                </div>
                <div class="w-100 d-none d-md-block d-lg-none"><!-- wrap every 3 on md--></div>
                <div class="card mb-4">
                    <a routerLink="/author-profile">
                    <img src="./../../assets/image/bloguser3.jpg" class="card-img-top" alt="...">
                    <div class="card-body">
                        <h3 class="card-title text-white pb-3">Colin Delgado</h3>
                        <img class="micon image " src="./../../assets/image/Group 372.svg">&nbsp;&nbsp;
                        <img class="micon" src="./../../assets/image/Group 371.svg">&nbsp;&nbsp;
                        <img class="micon" src="./../../assets/image/Group 370.svg">&nbsp;&nbsp;
                        <img class="micon" src="./../../assets/image/Group 425.svg">&nbsp;&nbsp;
                        <img class="micon" src="./../../assets/image/Group 369.svg">&nbsp;&nbsp;
                    </div>
                    <div class="overlay">
                    </div></a>
                </div>
                <div class="w-100 d-none d-sm-block d-md-none"><!-- wrap every 2 on sm--></div>
                 <div class="w-100 d-none d-lg-block d-xl-none"><!-- wrap every 4 on lg--></div>
                 <div class="card mb-4">
                    <a routerLink="/author-profile">
                    <img src="./../../assets/image/bloguser3.jpg" class="card-img-top" alt="...">
                    <div class="card-body">
                        <h3 class="card-title text-white pb-3">Colin Delgado</h3>
                        <img class="micon image " src="./../../assets/image/Group 372.svg">&nbsp;&nbsp;
                        <img class="micon" src="./../../assets/image/Group 371.svg">&nbsp;&nbsp;
                        <img class="micon" src="./../../assets/image/Group 370.svg">&nbsp;&nbsp;
                        <img class="micon" src="./../../assets/image/Group 425.svg">&nbsp;&nbsp;
                        <img class="micon" src="./../../assets/image/Group 369.svg">&nbsp;&nbsp;
                    </div>
                    <div class="overlay">
                    </div></a>
                </div>
                <div class="w-100 d-none d-xl-block"><!-- wrap every 5 on xl--></div>
                <div class="card mb-4">
                    <a routerLink="/author-profile">
                    <img src="./../../assets/image/bloguser3.jpg" class="card-img-top" alt="...">
                    <div class="card-body">
                        <h3 class="card-title text-white pb-3">Colin Delgado</h3>
                        <img class="micon image " src="./../../assets/image/Group 372.svg">&nbsp;&nbsp;
                        <img class="micon" src="./../../assets/image/Group 371.svg">&nbsp;&nbsp;
                        <img class="micon" src="./../../assets/image/Group 370.svg">&nbsp;&nbsp;
                        <img class="micon" src="./../../assets/image/Group 425.svg">&nbsp;&nbsp;
                        <img class="micon" src="./../../assets/image/Group 369.svg">&nbsp;&nbsp;
                    </div>
                    <div class="overlay">
                    </div></a>
                </div>
                <div class="w-100 d-none d-sm-block d-md-none"><!-- wrap every 2 on sm--></div>
                <div class="w-100 d-none d-md-block d-lg-none"><!-- wrap every 3 on md--></div>
                <div class="card mb-4">
                    <a routerLink="/author-profile">
                    <img src="./../../assets/image/bloguser3.jpg" class="card-img-top" alt="...">
                    <div class="card-body">
                        <h3 class="card-title text-white pb-3">Colin Delgado</h3>
                        <img class="micon image " src="./../../assets/image/Group 372.svg">&nbsp;&nbsp;
                        <img class="micon" src="./../../assets/image/Group 371.svg">&nbsp;&nbsp;
                        <img class="micon" src="./../../assets/image/Group 370.svg">&nbsp;&nbsp;
                        <img class="micon" src="./../../assets/image/Group 425.svg">&nbsp;&nbsp;
                        <img class="micon" src="./../../assets/image/Group 369.svg">&nbsp;&nbsp;
                    </div>
                    <div class="overlay">
                    </div></a>
                </div>
                <div class="card mb-4">
                    <a routerLink="/author-profile">
                    <img src="./../../assets/image/bloguser3.jpg" class="card-img-top" alt="...">
                    <div class="card-body">
                        <h3 class="card-title text-white pb-3">Colin Delgado</h3>
                        <img class="micon image " src="./../../assets/image/Group 372.svg">&nbsp;&nbsp;
                        <img class="micon" src="./../../assets/image/Group 371.svg">&nbsp;&nbsp;
                        <img class="micon" src="./../../assets/image/Group 370.svg">&nbsp;&nbsp;
                        <img class="micon" src="./../../assets/image/Group 425.svg">&nbsp;&nbsp;
                        <img class="micon" src="./../../assets/image/Group 369.svg">&nbsp;&nbsp;
                    </div>
                    <div class="overlay">
                    </div></a>
                </div>
                <div class="w-100 d-none d-sm-block d-md-none"><!-- wrap every 2 on sm--></div>
                <div class="w-100 d-none d-lg-block d-xl-none"><!-- wrap every 4 on lg--></div>
                <div class="card mb-4">
                    <a routerLink="/author-profile">
                    <img src="./../../assets/image/bloguser3.jpg" class="card-img-top" alt="...">
                    <div class="card-body">
                        <h3 class="card-title text-white pb-3">Colin Delgado</h3>
                        <img class="micon image " src="./../../assets/image/Group 372.svg">&nbsp;&nbsp;
                        <img class="micon" src="./../../assets/image/Group 371.svg">&nbsp;&nbsp;
                        <img class="micon" src="./../../assets/image/Group 370.svg">&nbsp;&nbsp;
                        <img class="micon" src="./../../assets/image/Group 425.svg">&nbsp;&nbsp;
                        <img class="micon" src="./../../assets/image/Group 369.svg">&nbsp;&nbsp;
                    </div>
                    <div class="overlay">
                    </div></a>
                </div>
                <div class="w-100 d-none d-md-block d-lg-none"><!-- wrap every 3 on md--></div>
                <div class="card mb-4">
                    <a routerLink="/author-profile">
                    <img src="./../../assets/image/bloguser3.jpg" class="card-img-top" alt="...">
                    <div class="card-body">
                        <h3 class="card-title text-white pb-3">Colin Delgado</h3>
                        <img class="micon image " src="./../../assets/image/Group 372.svg">&nbsp;&nbsp;
                        <img class="micon" src="./../../assets/image/Group 371.svg">&nbsp;&nbsp;
                        <img class="micon" src="./../../assets/image/Group 370.svg">&nbsp;&nbsp;
                        <img class="micon" src="./../../assets/image/Group 425.svg">&nbsp;&nbsp;
                        <img class="micon" src="./../../assets/image/Group 369.svg">&nbsp;&nbsp;
                    </div>
                    <div class="overlay">
                    </div></a>
                </div>
            </div>
        </div>

    </div>
</div>

<div class="container cc-container  ">
   
    <div class="row">
        <div class="col-md-3"></div>
        <div class="col-md-1">
            <a routerLink="/"> <h2 class="text-white mob_t ">Parable <br> Symptoms</h2></a>
        </div>
        <div class="col-md-3 text-white">
            <h2 class=" display-4 mob_t">
                0&nbsp;2&nbsp;.&nbsp;0&nbsp;2&nbsp;.&nbsp;2&nbsp;0&nbsp;2&nbsp;0</h2>
        </div>
        <div class="col-md-2 text-white">
          <h2 class=" display-4 mob_t">
              0&nbsp;2&nbsp;.&nbsp;0&nbsp;2&nbsp;.&nbsp;2&nbsp;0&nbsp;2&nbsp;0</h2>
      </div>
        <div class="col-md-3"></div>
    </div>

    <div class="row">
        <div class="col-md-12">
            <h5 class="text-white text-center">&copy; 2020 Parable Symptom. All right reserved Terms of use privacy
                policy</h5>
        </div>

    </div>
</div>






























