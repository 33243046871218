<div class="container123">
<div class="container  ">
    <div class="row pt-4 pb-2 line ">
        <div class="col-md-3">
            <h1 class="text-white country  ">Brazil</h1>
        </div>
        <div class="col-md-4 float-right">

            <form class="example py-3" action="/action_page.php">
                <input type="text" class="text-white" placeholder="Search.." name="search2">

            </form>
        </div>
        <div class="col-md-2 py-3 sortt">
            <h1 class=" list1   data-toggle ="dropdown  > Sort By</h1>
        </div>
        <div class="col-md-2 py-3">
          
            <div class="dropdown mobdrop">
            
                <button class="btn list1 " data-toggle="dropdown"> Oldest&nbsp;&nbsp;<img class=" float-right" src="./../../assets/image/Icon feather-filter.svg" class=filter></button>
                <div class="dropdown-menu">
                    <ul class="texx">
                       
                            <li class="text-white pt-2"  class="dropdown-item">&nbsp;&nbsp;Oldest</li>
                       
                     
                            <li class="text-white pt-2"  class="dropdown-item">&nbsp;&nbsp;Newest</li>
                      
                      
                    </ul>
                </div>
              </div>
        </div>
        <div class="col-md-1">
            <a routerLink="/"> <img src="./../../assets/image/Component 7 – 18.svg" class="tilee" alt="..."></a>
        </div>
    </div>

    <div class="row py-3 ">
        <div class="col-md-2">
            <h1 class=" list active  ">Blog List</h1>

        </div>
        <div class="col-md-10">

            <a routerLink="/userlist"> <h1 class=" list1 moblist  ">User List</h1></a>
        </div>
    </div>
    <div class="row py-3 ">
        <div class="col-md-4">
          

        </div>
        <div class="col-md-8">


        </div>
    </div>
    <div class="row py-3 ">
        <div class="col-md-12">


            <!-- <div class="img__wrap">
                <img class="img__img" src="http://placehold.it/257x200.jpg" />
                <p class="img__description">This image looks super neat.</p>
              </div> -->

            <div class="gallery" id="gallery">
                <a routerLink="/publish">
                <div class="mb-3 pics animation all 2 img__wrap">
                   <img class="img-fluid mg__img" src="./../../assets/image/bloguser3.jpg" alt="Card image cap">
                    <h3 class="img__description p-4 ">Nina Las Vegas: Australia’s Triple Threat<p>03.12.2015</p><p class="sometext1">Sometimes you never
                         know your true calling until after you receive an unanticipated gig. For Australian triple threat Nina Las Vegas – renowned
                          Triple J radio host, DJ, and producer – those words certainly hit close to home.</p></h3>
                 
                  
                </div></a>
                <a routerLink="/publish">
                <div class="mb-3 pics animation all 2 img__wrap">
                    <img class="img-fluid mg__img" src="./../../assets/image/bloguser3.jpg" alt="Card image cap">
                    <h3 class="img__description p-4 ">Nina Las Vegas: Australia’s Triple Threat<p>03.12.2015</p><p class="sometext1">Sometimes you never
                        know your true calling until after you receive an unanticipated gig. For Australian triple threat Nina Las Vegas – renowned
                         Triple J radio host, DJ, and producer – those words certainly hit close to home.</p></h3>
                </div></a>
                <a routerLink="/publish">
                <div class="mb-3 pics animation all 1 img__wrap">
                    <img class="img-fluid img  mg__img" src="./../../assets/image/bloguser3.jpg" alt="Card image cap">
                    <h3 class="img__description p-4 ">Nina Las Vegas: Australia’s Triple Threat<p>03.12.2015</p><p class="sometext">Sometimes you never
                        know your true calling until after you receive an unanticipated gig. For Australian triple threat Nina Las Vegas – renowned
                         Triple J radio host, DJ, and producer – those words certainly hit close to home.</p></h3>
                </div></a>
                <a routerLink="/publish">
                <div class="mb-3 pics animation all 2 img__wrap">
                    <img class="img-fluid mg__img" src="./../../assets/image/bloguser3.jpg" alt="Card image cap">
                    <h3 class="img__description p-4 ">Nina Las Vegas: Australia’s Triple Threat<p>03.12.2015</p><p class="sometext1">Sometimes you never
                        know your true calling until after you receive an unanticipated gig. For Australian triple threat Nina Las Vegas – renowned
                         Triple J radio host, DJ, and producer – those words certainly hit close to home.</p></h3>
                </div>
</a>
<a routerLink="/publish">
                <div class="mb-3 pics animation all 2 img__wrap">
                    <img class="img-fluid mg__img" src="./../../assets/image/bloguser3.jpg" alt="Card image cap">
                    <h3 class="img__description p-4 ">Nina Las Vegas: Australia’s Triple Threat<p>03.12.2015</p><p class="sometext1">Sometimes you never
                        know your true calling until after you receive an unanticipated gig. For Australian triple threat Nina Las Vegas – renowned
                         Triple J radio host, DJ, and producer – those words certainly hit close to home.</p></h3>
                </div>
                </a>
                <a routerLink="/publish">
                <div class="mb-3 pics animation all 1 img__wrap">
                    <img class="img-fluid img  mg__img" src="./../../assets/image/bloguser3.jpg" alt="Card image cap">
                    <h3 class="img__description p-4 ">Nina Las Vegas: Australia’s Triple Threat<p>03.12.2015</p><p class="sometext">Sometimes you never
                        know your true calling until after you receive an unanticipated gig. For Australian triple threat Nina Las Vegas – renowned
                         Triple J radio host, DJ, and producer – those words certainly hit close to home.</p></h3>
                </div>
                </a>
                <a routerLink="/publish">
                <div class="mb-3 pics animation all 1 img__wrap">
                    <img class="img-fluid  mg__img" src="./../../assets/image/bloguser3.jpg" alt="Card image cap">
                    <h3 class="img__description p-4 ">Nina Las Vegas: Australia’s Triple Threat<p>03.12.2015</p><p class="sometext1">Sometimes you never
                        know your true calling until after you receive an unanticipated gig. For Australian triple threat Nina Las Vegas – renowned
                         Triple J radio host, DJ, and producer – those words certainly hit close to home.</p></h3>
                
                </div>
                </a>
                <a routerLink="/publish">
                <div class="mb-3 pics animation all 2 img__wrap">
                    <img class="img-fluid mg__img" src="./../../assets/image/bloguser3.jpg" alt="Card image cap">
                    <h3 class="img__description p-4 ">Nina Las Vegas: Australia’s Triple Threat<p>03.12.2015</p><p class="sometext1">Sometimes you never
                        know your true calling until after you receive an unanticipated gig. For Australian triple threat Nina Las Vegas – renowned
                         Triple J radio host, DJ, and producer – those words certainly hit close to home.</p></h3>
                </div>
                </a>
               
            </div>


            <div class="gallery" id="gallery">
                <a routerLink="/publish">
                <div class="mb-3 pics animation all 2 img__wrap">
                    <img class="img-fluid mg__img" src="./../../assets/image/bloguser3.jpg" alt="Card image cap">
                    <h3 class="img__description p-4 ">Nina Las Vegas: Australia’s Triple Threat<p>03.12.2015</p><p class="sometext1">Sometimes you never
                         know your true calling until after you receive an unanticipated gig. For Australian triple threat Nina Las Vegas – renowned
                          Triple J radio host, DJ, and producer – those words certainly hit close to home.</p></h3>
                </div>
                </a>
                <a routerLink="/publish">
                <div class="mb-3 pics animation all 2 img__wrap">
                    <img class="img-fluid mg__img" src="./../../assets/image/bloguser3.jpg" alt="Card image cap">
                    <h3 class="img__description p-4 ">Nina Las Vegas: Australia’s Triple Threat<p>03.12.2015</p><p class="sometext1">Sometimes you never
                        know your true calling until after you receive an unanticipated gig. For Australian triple threat Nina Las Vegas – renowned
                         Triple J radio host, DJ, and producer – those words certainly hit close to home.</p></h3>
                </div>
                </a>
                <a routerLink="/publish">
                <div class="mb-3 pics animation all 1 img__wrap">
                    <img class="img-fluid img  mg__img" src="./../../assets/image/bloguser3.jpg" alt="Card image cap">
                    <h3 class="img__description p-4 ">Nina Las Vegas: Australia’s Triple Threat<p>03.12.2015</p><p class="sometext">Sometimes you never
                        know your true calling until after you receive an unanticipated gig. For Australian triple threat Nina Las Vegas – renowned
                         Triple J radio host, DJ, and producer – those words certainly hit close to home.</p></h3>
                </div>
                </a>
                <a routerLink="/publish">
                <div class="mb-3 pics animation all 2 img__wrap">
                    <img class="img-fluid mg__img" src="./../../assets/image/bloguser3.jpg" alt="Card image cap">
                    <h3 class="img__description p-4 ">Nina Las Vegas: Australia’s Triple Threat<p>03.12.2015</p><p class="sometext1">Sometimes you never
                        know your true calling until after you receive an unanticipated gig. For Australian triple threat Nina Las Vegas – renowned
                         Triple J radio host, DJ, and producer – those words certainly hit close to home.</p></h3>
                </div>
                </a>
                <a routerLink="/publish">
                <div class="mb-3 pics animation all 2 img__wrap">
                    <img class="img-fluid mg__img" src="./../../assets/image/bloguser3.jpg" alt="Card image cap">
                    <h3 class="img__description p-4 ">Nina Las Vegas: Australia’s Triple Threat<p>03.12.2015</p><p class="sometext1">Sometimes you never
                        know your true calling until after you receive an unanticipated gig. For Australian triple threat Nina Las Vegas – renowned
                         Triple J radio host, DJ, and producer – those words certainly hit close to home.</p></h3>
                </div>
                </a>
                <a routerLink="/publish">
                <div class="mb-3 pics animation all 1 img__wrap">
                    <img class="img-fluid img  mg__img" src="./../../assets/image/bloguser3.jpg" alt="Card image cap">
                    <h3 class="img__description p-4 ">Nina Las Vegas: Australia’s Triple Threat<p>03.12.2015</p><p class="sometext">Sometimes you never
                        know your true calling until after you receive an unanticipated gig. For Australian triple threat Nina Las Vegas – renowned
                         Triple J radio host, DJ, and producer – those words certainly hit close to home.</p></h3>
                </div>
                </a>
                <a routerLink="/publish">
                <div class="mb-3 pics animation all 1 img__wrap">
                    <img class="img-fluid  mg__img" src="./../../assets/image/bloguser3.jpg" alt="Card image cap">
                    <h3 class="img__description p-4 ">Nina Las Vegas: Australia’s Triple Threat<p>03.12.2015</p><p class="sometext1">Sometimes you never
                        know your true calling until after you receive an unanticipated gig. For Australian triple threat Nina Las Vegas – renowned
                         Triple J radio host, DJ, and producer – those words certainly hit close to home.</p></h3>
                
                </div></a>
                <a routerLink="/publish">
                <div class="mb-3 pics animation all 2 img__wrap">
                    <img class="img-fluid mg__img" src="./../../assets/image/bloguser4.jpg" alt="Card image cap">
                    <h3 class="img__description p-4 ">Nina Las Vegas: Australia’s Triple Threat<p>03.12.2015</p><p class="sometext1">Sometimes you never
                        know your true calling until after you receive an unanticipated gig. For Australian triple threat Nina Las Vegas – renowned
                         Triple J radio host, DJ, and producer – those words certainly hit close to home.</p></h3>
                </div>
                </a>
               
            </div>

            <div class="gallery" id="gallery">
                <a routerLink="/publish">
                <div class="mb-3 pics animation all 2 img__wrap">
                    <img class="img-fluid mg__img" src="./../../assets/image/bloguser4.jpg" alt="Card image cap">
                    <h3 class="img__description p-4 ">Nina Las Vegas: Australia’s Triple Threat<p>03.12.2015</p><p class="sometext1">Sometimes you never
                         know your true calling until after you receive an unanticipated gig. For Australian triple threat Nina Las Vegas – renowned
                          Triple J radio host, DJ, and producer – those words certainly hit close to home.</p></h3>
                 
                  
                </div></a>
                <a routerLink="/publish">
                <div class="mb-3 pics animation all 2 img__wrap">
                    <img class="img-fluid mg__img" src="./../../assets/image/bloguser4.jpg" alt="Card image cap">
                    <h3 class="img__description p-4 ">Nina Las Vegas: Australia’s Triple Threat<p>03.12.2015</p><p class="sometext1">Sometimes you never
                        know your true calling until after you receive an unanticipated gig. For Australian triple threat Nina Las Vegas – renowned
                         Triple J radio host, DJ, and producer – those words certainly hit close to home.</p></h3>
                </div>
                </a>
                <a routerLink="/publish">
                <div class="mb-3 pics animation all 1 img__wrap">
                    <img class="img-fluid img  mg__img" src="./../../assets/image/bloguser4.jpg" alt="Card image cap">
                    <h3 class="img__description p-4 ">Nina Las Vegas: Australia’s Triple Threat<p>03.12.2015</p><p class="sometext">Sometimes you never
                        know your true calling until after you receive an unanticipated gig. For Australian triple threat Nina Las Vegas – renowned
                         Triple J radio host, DJ, and producer – those words certainly hit close to home.</p></h3>
                </div>
                </a>
                <a routerLink="/publish">
                <div class="mb-3 pics animation all 2 img__wrap">
                    <img class="img-fluid mg__img" src="./../../assets/image/bloguser4.jpg" alt="Card image cap">
                    <h3 class="img__description p-4 ">Nina Las Vegas: Australia’s Triple Threat<p>03.12.2015</p><p class="sometext1">Sometimes you never
                        know your true calling until after you receive an unanticipated gig. For Australian triple threat Nina Las Vegas – renowned
                         Triple J radio host, DJ, and producer – those words certainly hit close to home.</p></h3>
                </div>
                </a>
                <a routerLink="/publish">
                <div class="mb-3 pics animation all 2 img__wrap">
                    <img class="img-fluid mg__img" src="./../../assets/image/bloguser4.jpg" alt="Card image cap">
                    <h3 class="img__description p-4 ">Nina Las Vegas: Australia’s Triple Threat<p>03.12.2015</p><p class="sometext1">Sometimes you never
                        know your true calling until after you receive an unanticipated gig. For Australian triple threat Nina Las Vegas – renowned
                         Triple J radio host, DJ, and producer – those words certainly hit close to home.</p></h3>
                </div></a>
                <a routerLink="/publish">
                <div class="mb-3 pics animation all 1 img__wrap">
                    <img class="img-fluid img  mg__img" src="./../../assets/image/bloguser4.jpg" alt="Card image cap">
                    <h3 class="img__description p-4 ">Nina Las Vegas: Australia’s Triple Threat<p>03.12.2015</p><p class="sometext">Sometimes you never
                        know your true calling until after you receive an unanticipated gig. For Australian triple threat Nina Las Vegas – renowned
                         Triple J radio host, DJ, and producer – those words certainly hit close to home.</p></h3>
                </div>
                </a>
                <a routerLink="/publish">
                <div class="mb-3 pics animation all 1 img__wrap">
                    <img class="img-fluid  mg__img" src="./../../assets/image/bloguser4.jpg" alt="Card image cap">
                    <h3 class="img__description p-4 ">Nina Las Vegas: Australia’s Triple Threat<p>03.12.2015</p><p class="sometext1">Sometimes you never
                        know your true calling until after you receive an unanticipated gig. For Australian triple threat Nina Las Vegas – renowned
                         Triple J radio host, DJ, and producer – those words certainly hit close to home.</p></h3>
                
                </div></a>
                <a routerLink="/publish">
                <div class="mb-3 pics animation all 2 img__wrap">
                    <img class="img-fluid mg__img" src="./../../assets/image/bloguser4.jpg" alt="Card image cap">
                    <h3 class="img__description p-4 ">Nina Las Vegas: Australia’s Triple Threat<p>03.12.2015</p><p class="sometext1">Sometimes you never
                        know your true calling until after you receive an unanticipated gig. For Australian triple threat Nina Las Vegas – renowned
                         Triple J radio host, DJ, and producer – those words certainly hit close to home.</p></h3>
                </div>
                </a>
               
            </div>
            
        </div>
      
    </div>
  

</div>
</div>
<div class="container cc-container  ">
   
    <div class="row">
        <div class="col-md-3"></div>
        <div class="col-md-1">
            <a routerLink="/"> <h2 class="text-white mob_t ">Parable <br> Symptoms</h2></a>
        </div>
        <div class="col-md-3 text-white">
            <h2 class=" display-4 mob_t">
                0&nbsp;2&nbsp;.&nbsp;0&nbsp;2&nbsp;.&nbsp;2&nbsp;0&nbsp;2&nbsp;0</h2>
        </div>
        <div class="col-md-2 text-white">
          <h2 class=" display-4 mob_t">
              0&nbsp;2&nbsp;.&nbsp;0&nbsp;2&nbsp;.&nbsp;2&nbsp;0&nbsp;2&nbsp;0</h2>
      </div>
        <div class="col-md-3"></div>
    </div>
    <div class="row">
        <div class="col-md-12">
            <h5 class="text-white text-center">&copy; 2020 Parable Symptom. All right reserved Terms of use privacy
                policy</h5>
        </div>

    </div>
</div>




