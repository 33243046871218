<div class="container123">
    <div class="container  ">
        <div class="row allsec">

            <div class="col-md-8 pt-5  ">
                <h3 class="text-white display-4 text_c py-1 mobile_tex">
                    <img class="img-r" src="./../../assets/image/bloguser5.jpg"> Kai Acevedo
                </h3>
                <h3 class="f_text pt-3">About</h3>
                <h3 class=" name   f_text pt-3">Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod
                    tempor
                    incididunt ut labore
                    et dolore magna aliqua.</h3>
            </div>
            <div class="col-md-2 mt-5 pt-3   float-right box">
                <div class="row">
                    <div class="col-md-12 pt-2">
                        <a routerLink="/edit_profile"> <button class="btn success f_text  ">Edit Profile</button></a>
                        <h6 class="    f_text py-4">203k Followers</h6>
                    </div>
                </div>
                <div class="row pb-4 ">
                    <div class="col-md-12">
                        <img class="micon1  " src="./../../assets/image/Group 372.svg">&nbsp;
                        <img class="micon1" src="./../../assets/image/Group 371.svg">&nbsp;
                        <img class="micon1" src="./../../assets/image/Group 370.svg">&nbsp;
                        <img class="micon1" src="./../../assets/image/Group 425.svg">&nbsp;
                        <img class="micon1" src="./../../assets/image/Group 369.svg">&nbsp;
                    </div>
                </div>
            </div>
            <div class="col-md-2">
                <a routerLink="/"> <img class="tilee " src="./../../assets/image/Component 7 – 18.svg"></a>
            </div>
        </div>
        <div class="row allsec">
            <div class="col-md-8 line ">
                <div class="row line pt-2 ">
                  
                    
<div class="topnav">
    <a class="active name f_text" href="#home">Published</a>
    <a href="#" class=" name f_text">Save Story</a>
    <a href="#" class="name f_text">Draft1</a>
  </div>
                </div>
                <h3 class="f_text pt-3">January-2019</h3>
                <h1 class="text-white  text_h pb-5 mobile_tex pt-3">Choosing To be Vulnerable in My Wrinting - Warts And
                    All
                </h1>
                <img class="image pb-2" src="./../../assets/image/sport.jpg">
                <p class=" name f_text"> Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor
                    incididunt ut labore
                    et dolore magna aliqua. Ut enim ad minim veniam, quis nostrud exercitation ullamco laboris nisi ut
                    aliquip ex ea commodo consequat. </p>
                <div class="row line py-3">
                    <div class="col-md-3">
                        <h4 class="text-white float-left "> <img class="img_b"
                                src="./../../assets/image/Group 436.svg">
                        </h4>
                        <h4 class="text-white f_text  ">&nbsp;&nbsp;1,173 claps</h4>
                    </div>
                    <div class="col-md-3">
                        <h4 class="text-white float-left "> <img class="img_b"
                                src="./../../assets/image/Group 437 (1).svg">
                        </h4>
                        <h4 class="text-white f_text  "> &nbsp;&nbsp;4 responses</h4>
                    </div>
                    <div class="col-md-2">
                        <h4 class="text-white float-left "> <img class="img_b"
                                src="./../../assets/image/Group 438.svg">
                        </h4>
                        <h4 class="text-white f_text">&nbsp;&nbsp;Saved</h4>
                    </div>
                    <div class="col-md-4">
                        <div class="row pb-4  iconmob">
                            <div class="col-md-12  ">
                                <span class="text-white f_text">Share</span> &nbsp;&nbsp;
                                <img class="micon  " src="./../../assets/image/Group 372.svg">&nbsp;&nbsp;
                                <img class="micon" src="./../../assets/image/Group 371.svg">&nbsp;&nbsp;
                                <img class="micon" src="./../../assets/image/Group 370.svg">&nbsp;&nbsp;
                                <img class="micon" src="./../../assets/image/Group 425.svg">&nbsp;&nbsp;
                                <img class="micon" src="./../../assets/image/Group 369.svg">&nbsp;&nbsp;
                            </div>
                        </div>
                    </div>
                </div>
                <h3 class="f_text pt-5 mt-4">January-2019</h3>
                <h1 class="text-white  text_h pb-5 mobile_tex pt-3">Choosing To be Vulnerable in My Wrinting - Warts And
                    All
                </h1>
                <img class="image pb-2" src="./../../assets/image/mike.jpg">
                <p class=" name f_text"> Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor
                    incididunt ut labore
                    et dolore magna aliqua. Ut enim ad minim veniam, quis nostrud exercitation ullamco laboris nisi ut
                    aliquip ex ea commodo consequat. </p>
                <div class="row  py-3">
                    <div class="col-md-3">
                        <h4 class="text-white float-left "> <img class="img_b"
                                src="./../../assets/image/Group 436.svg">
                        </h4>
                        <h4 class="text-white f_text  ">&nbsp;&nbsp;1,173 claps</h4>
                    </div>
                    <div class="col-md-3">
                        <h4 class="text-white float-left "> <img class="img_b"
                                src="./../../assets/image/Group 437 (1).svg">
                        </h4>
                        <h4 class="text-white f_text  "> &nbsp;&nbsp;4 responses</h4>
                    </div>
                    <div class="col-md-2">
                        <h4 class="text-white float-left "> <img class="img_b"
                                src="./../../assets/image/Group 438.svg">
                        </h4>
                        <h4 class="text-white f_text">&nbsp;&nbsp;Saved</h4>
                    </div>
                    <div class="col-md-4">
                        <div class="row pb-4  iconmob">
                            <div class="col-md-12  ">
                                <span class="text-white f_text">Share</span> &nbsp;&nbsp;
                                <img class="micon  " src="./../../assets/image/Group 372.svg">&nbsp;
                                <img class="micon" src="./../../assets/image/Group 371.svg">&nbsp;
                                <img class="micon" src="./../../assets/image/Group 370.svg">&nbsp;
                                <img class="micon" src="./../../assets/image/Group 425.svg">&nbsp;
                                <img class="micon" src="./../../assets/image/Group 369.svg">&nbsp;
                            </div>
                        </div>
                    </div>
                </div>
                <h3 class="f_text pt-5">January-2019</h3>
                <h1 class="text-white  text_h pb-5 mobile_tex pt-3">Choosing To be Vulnerable in My Wrinting - Warts And
                    All
                </h1>
                <img class="image pb-2" src="./../../assets/image/sport.jpg">
                <p class=" name f_text"> Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor
                    incididunt ut labore
                    et dolore magna aliqua. Ut enim ad minim veniam, quis nostrud exercitation ullamco laboris nisi ut
                    aliquip ex ea commodo consequat. </p>
                <div class="row line py-3">
                    <div class="col-md-3">
                        <h4 class="text-white float-left "> <img class="img_b"
                                src="./../../assets/image/Group 436.svg">
                        </h4>
                        <h4 class="text-white f_text  ">&nbsp;&nbsp;1,173 claps</h4>
                    </div>
                    <div class="col-md-3">
                        <h4 class="text-white float-left "> <img class="img_b"
                                src="./../../assets/image/Group 437 (1).svg">
                        </h4>
                        <h4 class="text-white f_text  "> &nbsp;&nbsp;4 responses</h4>
                    </div>
                    <div class="col-md-2">
                        <h4 class="text-white float-left "> <img class="img_b"
                                src="./../../assets/image/Group 438.svg">
                        </h4>
                        <h4 class="text-white f_text">&nbsp;&nbsp;Saved</h4>
                    </div>
                    <div class="col-md-4">
                        <div class="row pb-4  iconmob">
                            <div class="col-md-12  ">
                                <span class="text-white f_text">Share</span> &nbsp;&nbsp;
                                <img class="micon  " src="./../../assets/image/Group 372.svg">&nbsp;
                                <img class="micon" src="./../../assets/image/Group 371.svg">&nbsp;
                                <img class="micon" src="./../../assets/image/Group 370.svg">&nbsp;
                                <img class="micon" src="./../../assets/image/Group 425.svg">&nbsp;
                                <img class="micon" src="./../../assets/image/Group 369.svg">&nbsp;
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            <div class="col-md-2 mt-5 pt-3   float-right box">
                <div class="row">
                    <div class="col-md-12 pt-2">
                        <h6 class="    f_text py-4">Other Authors</h6>
                    </div>
                </div>
                <div class="row pb-4 ">
                    <div class="col-md-3">
                        <img class="img-comment dropdown-toggle " data-toggle="dropdown"
                            src="./../../assets/image/bloguser3.jpg">
                    </div>
                    <div class="col-md-9">
                        <h4 class="f_text mobauthor">Alex Authors</h4>
                        <h6 class="f_text mobauthor">See Profile <img class="img-comment1 dropdown-toggle " data-toggle="dropdown"
                            src="./../../assets/image/Group 355 (1).svg"> </h6>
                    </div>
                </div>
                <div class="row pb-4 ">
                    <div class="col-md-3">
                        <img class="img-comment dropdown-toggle " data-toggle="dropdown"
                            src="./../../assets/image/bloguser3.jpg">
                    </div>
                    <div class="col-md-9">
                        <h4 class="f_text mobauthor">Alex Authors</h4>
                        <h6 class="f_text mobauthor ">See Profile <img class="img-comment1 dropdown-toggle  " data-toggle="dropdown"
                            src="./../../assets/image/Group 355 (1).svg"></h6>
                    </div>
                </div>
                <div class="row pb-4 ">
                    <div class="col-md-3">
                        <img class="img-comment dropdown-toggle " data-toggle="dropdown"
                            src="./../../assets/image/bloguser3.jpg">
    
                    </div>
                    <div class="col-md-9">
                        <h4 class="f_text mobauthor">Alex Authors</h4>
                        <h6 class="f_text mobauthor ">See Profile <img class="img-comment1 dropdown-toggle" data-toggle="dropdown"
                            src="./../../assets/image/Group 355 (1).svg"> </h6>
                    </div>
                </div>
                <div class="row pb-4 ">
                    <div class="col-md-3">
                        <img class="img-comment dropdown-toggle " data-toggle="dropdown"
                            src="./../../assets/image/bloguser3.jpg">
    
                    </div>
                    <div class="col-md-9">
                        <h4 class="f_text mobauthor">Alex Authors</h4>
                        <h6 class="f_text mobauthor ">See Profile <img class="img-comment1 dropdown-toggle  " data-toggle="dropdown"
                            src="./../../assets/image/Group 355 (1).svg"> </h6>
                    </div>
                </div>
                <div class="row pb-4 ">
                    <div class="col-md-3">
                        <img class="img-comment dropdown-toggle " data-toggle="dropdown"
                            src="./../../assets/image/bloguser3.jpg">
    
                    </div>
                    <div class="col-md-9">
                        <h4 class="f_text mobauthor">Alex Authors</h4>
                        <h6 class="f_text mobauthor ">See Profile <img class="img-comment1 dropdown-toggle " data-toggle="dropdown"
                            src="./../../assets/image/Group 355 (1).svg"> </h6>
                    </div>
                </div>
                <div class="row">
                    <div class="col-md-12 pt-2">
                        <a routerLink="/more-author">   <h6 class="    f_text py-4">More Authors <img class="img-comment dropdown-toggle " data-toggle="dropdown"
                            src="./../../assets/image/Group 355 (1).svg"></h6></a>
                       
                    </div>
                </div>
            </div>
            <div class="col-md-2">

            </div>
        </div>
        <div class="row ">
            <div class="col-md-12 ">
                <h3 class="f_text pt-3">January-2019</h3>
                <h1 class=" text_h pb-5 mobile_tex pt-3 t_color">Choosing To be Vulnerable in My Wrinting - Warts And
                    All
                </h1>
                <div class="row text-center">
                    <div class="col-md-12 ">
                        <h1 class="text-white show text-center">Show More</h1>
                        <img class="img_f pb-4" src="./../../assets/image/Component 1 – 4.svg">
                    </div>
                </div>
            </div>
        </div>
       
    </div>
</div>
<div class="container cc-container  ">
   
    <div class="row">
        <div class="col-md-3"></div>
        <div class="col-md-1">
            <a routerLink="/"> <h2 class="text-white mob_t ">Parable <br> Symptoms</h2></a>
        </div>
        <div class="col-md-3 text-white">
            <h2 class=" display-4 mob_t">
                0&nbsp;2&nbsp;.&nbsp;0&nbsp;2&nbsp;.&nbsp;2&nbsp;0&nbsp;2&nbsp;0</h2>
        </div>
        <div class="col-md-2 text-white">
          <h2 class=" display-4 mob_t">
              0&nbsp;2&nbsp;.&nbsp;0&nbsp;2&nbsp;.&nbsp;2&nbsp;0&nbsp;2&nbsp;0</h2>
      </div>
        <div class="col-md-3"></div>
    </div>

    <div class="row">
        <div class="col-md-12">
            <h5 class="text-white text-center">&copy; 2020 Parable Symptom. All right reserved Terms of use privacy
                policy</h5>
        </div>

    </div>
</div>