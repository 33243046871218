<div class="container123 ">
    <div class="row fix-tile">
        <div class="col-md-10">
            <a routerLink="/"> <img class="float-right tilee " src="./../../assets/image/Component 7 – 18.svg"></a>

        </div>
        <div class="col-md-2"></div>
    </div>
 
    <div class="container ">
        <div class="row py-5">
            <div class="col-md-1"></div>
            <div class="col-md-4 fix">
                <h1 class="text-white setting ">Settings</h1><br>
                <a routerLink="/profile">   <h1 class="text-white pt-5 sec_p"> <img src="./../../assets/image/Group 355.svg" class="arrow"
                        alt="...">&nbsp;Go to profile</h1></a>
                <ul class="mobile-list">
                    <li class="text-white p-text pt-3">
                        <a href="javascript:void(0)" (click)="Navigate(profilepage)"
                        > <p class="text-white">profile</p></a>
                        
                    </li>
                    <li class="text-white p-text pt-3">
                        <a href="javascript:void(0)" (click)="Navigate(Connection)"
                        > <p class="text-white">Connection</p></a>
                    </li>
                    <li class="text-white p-text pt-3">
                        <a href="javascript:void(0)" (click)="Navigate(Security)"
                        >   <p class="text-white">security</p></a>
                    </li>
                </ul>
            </div>
            <div class="col-md-7 fix1" >

                <div #profilepage>
                <div class="py-5 mt-5"></div><br><br><br><br>
                <h1 class="text-white com_t">Profile</h1>
                <div class="row " >
                    <div class="col-md-2 pt-5  ">
                        <h4 class="text-white "> <img class="img-responsive" src="./../../assets/image/bloguser3.jpg">
                        </h4>
                    </div>
                    <div class="col-md-8  pt-5   ">

                        <p class="text-white name">Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do
                            eiusmod
                            tempor
                            incididunt ut labore</p>

                    </div>
                    <div class="col-md-2 pt-4 "><br>
                        <button class="btn success f_text py-2 px-5  b_text float-right ">Edit </button>
                    </div>
                </div>
                <br>


                <h2 class="text-white pt-3 com_t ">Name</h2>
                <div class="row ">
                    <div class="col-md-10   ">
                        <div class="form-group">
                            <input type="text" name="name" class="form-control" id="name" placeholder="Kai Acevedo">
                        </div>
                    </div>
                    <div class="col-md-2 pb-3">
                        <button class="btn success f_text py-2 px-5  b_text float-right ">Edit </button>
                    </div>
                </div>
                <br>

                <h2 class="text-white pt-3 com_t ">bio</h2>
                <div class="row ">
                    <div class="col-md-10">
                        <div class="form-group">
                            <input type="text" name="name" class="form-control" id="name" placeholder="Add Your Bio">
                        </div>
                    </div>
                    <div class="col-md-2 pb-3">
                        <button class="btn success f_text py-2 px-5  b_text float-right ">Edit </button>
                    </div>
                </div>
                <br>
                <h2 class="text-white pt-3 com_t ">URL</h2>
                <div class="row line pb-5 ">
                    <div class="col-md-6  ">
                        <div class="form-group">
                            <!-- <label for="name" class="control-label">Name</label> -->
                            <input type="text" name="name" class="form-control" id="name" placeholder="KoiAcevedodo2 ">
                        </div>
                    </div>
                    <div class="col-md-4 pt-4  ">
                        <h2 class="text-white pra_com">PrableSymptoms.com</h2>
                    </div>
                    <!-- <div class="col-md-2  pb-3 ">
                        <button class="btn success f_text py-2 px-5  b_text float-right ">Edit </button>
                    </div> -->
                </div>
            </div>


                <br>
                 <div #Connection>
                <h2 class="text-white pt-3">Connection</h2>
                
                <div class="row">
                    <div class="col-md-8 pt-5 ">
                        <h2 class="text-white  pt-3">Connect to Facebook</h2>
                    </div>
                    <div class="col-md-4 pt-5 text-right">
                        <button class="btnn w-100 py-3 text-white">
                            <h4><i class="fa fa-facebook text-white"></i> &nbsp;Connect to Facebook</h4>
                        </button>
                    </div>
                </div>
                <br>
                <div class="row  ">
                    <div class="col-md-8 pt-5  ">
                        <h2 class="text-white pt-3">Connect to Twitter</h2>
                    </div>
                    <div class="col-md-4 pt-5 text-right">
                        <button class="btnn1 w-100 py-3 text-white">
                            <h4><i class="fa fa-twitter text-white"></i>&nbsp; Connect to Twitter</h4>
                        </button>
                    </div>
                </div>
                <br>

                <div class="row  ">
                    <div class="col-md-8 pt-5  ">
                        <h2 class="text-white pt-3">Connect to Instagram</h2>
                    </div>
                    <div class="col-md-4 pt-5 text-right">
                        <button class="btnn2 w-100 py-3 text-white">
                            <h4><i class="fa fa-instagram text-white"></i>&nbsp; Connect to Instagram</h4>
                        </button>
                    </div>
                </div>
                <br>
                <div class="row  ">
                    <div class="col-md-8 pt-5  ">
                        <h2 class="text-white pt-3">Connect to linkedin</h2>
                    </div>
                    <div class="col-md-4 pt-5 text-right">
                        <button class="btnn3 w-100 py-3 text-white">
                            <h4><i class="fa fa-linkedin text-white"></i>&nbsp; Connect to linkedin</h4>
                        </button>
                    </div>
                </div>
            </div>

                <br>

                <div #Security>
                <div class="row line pb-5">
                    <div class="col-md-8 pt-5  ">
                        <h2 class="text-white pt-3">You are connected to Email</h2>
                    </div>
                    <div class="col-md-4 pt-5 text-right">
                        <button class="btnn4 w-100 py-3 text-white">
                            <h4><i class="fa fa-envelope text-white"></i>&nbsp; Disconnect</h4>
                        </button>
                    </div>
                </div>
                <br>
                <h2 class="text-white pt-3 com_t pb-1  ">Security</h2>
                <div class="row ">
                    <div class="col-md-8 ">
                        <h2 class="text-white  com_t  ">Sign out of all other Sessions</h2>
                        <p class="text-white name py-2">This Will sign you out of session in other browsers or on other computers </p>
                    </div>
                    <div class="col-md-4 text-right">
                        <button class="btn dark b_textpx-5 "><h4 class="text-white"> Sign Out </h4></button>
                    </div>
                </div>

                <div class="row account  ">
                    <div class="col-md-8   ">
                        <h2 class="text-white  com_t  ">Delete Account</h2>
                        <p class="text-white name  ">Permanently delete your account and all of your contant </p>
                    </div>
                    <div class="col-md-4 pt-5 text-right  pb-5 mb-5  ">
                        <button class="btn dark b_text  "> <h4 class="text-white">Delete Account</h4> </button>
                    </div>
                </div>
            </div>
                
            </div>

        </div>
    </div>
</div>

<div class="container cc-container  ">
   
    <div class="row">
        <div class="col-md-3"></div>
        <div class="col-md-1">
            <a routerLink="/"> <h2 class="text-white mob_t ">Parable <br> Symptoms</h2></a>
        </div>
        <div class="col-md-3 text-white">
            <h2 class=" display-4 mob_t">
                0&nbsp;2&nbsp;.&nbsp;0&nbsp;2&nbsp;.&nbsp;2&nbsp;0&nbsp;2&nbsp;0</h2>
        </div>
        <div class="col-md-2 text-white">
          <h2 class=" display-4 mob_t">
              0&nbsp;2&nbsp;.&nbsp;0&nbsp;2&nbsp;.&nbsp;2&nbsp;0&nbsp;2&nbsp;0</h2>
      </div>
        <div class="col-md-3"></div>
    </div>

    <div class="row">
        <div class="col-md-12">
            <h5 class="text-white text-center">&copy; 2020 Parable Symptom. All right reserved Terms of use privacy
                policy</h5>
        </div>

    </div>
</div>