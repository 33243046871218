<div class="container cc-container ">
    <div class="container ">
    <div class="row header">
      
        <!-- <div class="col-md-2 pt-3">
            <h1 class="text-white draft "><span class="draft"><img src="./../../assets/image/Group 355.svg"
                        class="arrow" alt="...">&nbsp;&nbsp;Back&nbsp;&nbsp;</span> </h1>
        </div> -->
        
        <div class="col-md-8 pt-3">
            <h1 class="text-white draft dmob "><a routerLink="/"> <span class="draft text-white"><img src="./../../assets/image/Group 355.svg"
                class="arrow" alt="...">&nbsp;&nbsp;Back</span></a>
                <span class="pl-5 ml-5"> &nbsp;&nbsp;Draft in User Name</span><span
                    class="f_text">&nbsp;&nbsp;saved</span> </h1>
        </div>
        <div class="col-md-2 ">
            <img class="img-comment dropdown-toggle dropdown" data-toggle="dropdown" src="./../../assets/image/bloguser3.jpg">
            <span class="text-white alex pt-5 dropdown" data-toggle="dropdown">&nbsp;Hi Alex</span>
            <br>
            <div class="dropdown-menu">
                <ul class="texx">
                    <a routerLink="/profile" >
                        <li class="text-white pt-2"  class="dropdown-item"><i class="fa fa-user"
                                aria-hidden="true"></i>&nbsp;&nbsp;Profile</li>
                    </a>
                    <a routerLink="/edit_profile">
                        <li class="text-white pt-2"  class="dropdown-item"><i class="fa fa-cog"
                                aria-hidden="true"></i>&nbsp;&nbsp;Setting</li>
                    </a>
                    <a routerLink="/logout">
                        <li class="text-white pt-2"  class="dropdown-item"><i class="fa fa-sign-out"
                                aria-hidden="true"></i>&nbsp;&nbsp;Logout</li>
                    </a>
                </ul>
            </div>
        </div>
        <div class="col-md-2 py-3 ">
            <a routerLink="/publish"> <button type="button" class="btn btn-success publish float-right">Publish</button></a>
        </div>
    </div>
</div>
</div>
<div class="create">
    <div class="container ">
        <div class="row  w-100">
            <div class="col-md-2">
              
            </div>
            <div class="col-md-7 pt-5 line">
                <h1 class="text-white draft selecttext ">Select Category </h1>
                <div class="row w-100">
                    <ul class=listbtn>
                        <li><button class="btn text-white  mt-5 b_ton">Style</button></li>
                        <li>   <button class="btn text-white  mt-5 b_ton">Leisure</button></li>
                        <li><button class="btn text-white  mt-5 b_ton">Art & Design</button></li>
                        <li><button class="btn text-white  mt-5 b_ton">Technology</button></li>
                     
                    </ul>
                    <ul class=listbtn>
                      
                        <li><button class="btn text-white  mt-5 b_ton">Music</button></li>
                        <li><button class="btn text-white  mt-5 b_ton">Sports</button></li>
                        <li>   <button class="btn text-white  mt-5 b_ton">Roundtable</button></li>
                        <li><button class="btn text-white  mt-5 b_ton">Decoded</button></li>
                    </ul>
                  
                </div>
                <br>
                <div class="row w-100">
                   
                    <div class="dropdown">
                        <!-- <button class="btn btn-secondary dropdown-toggle" type="button" id="dropdownMenuButton" data-toggle="dropdown" aria-haspopup="true" aria-expanded="false">
                          Dropdown button
                        </button> -->
                        <div class="col-md-2">
                            <img class="addicon pl-3 dropdown-toggle" id="plus" src="./../../assets/image/add.png"  id="dropdownMenuButton" data-toggle="dropdown" aria-haspopup="true" aria-expanded="false">
                       
                        <div class="dropdown-menu hh" aria-labelledby="dropdownMenuButton ">
                          <a class="ml-3" href="#"> <img class=" "  src="./../../assets/image/add.png"></a>
                          <a class="ml-3" href="#"><img class=" "  src="./../../assets/image/add.png"></a>
                          <a class="ml-3" href="#"><img class=" "  src="./../../assets/image/add.png"></a>
                        </div>
                      </div> </div>

                    <div class="col-md-10">
                        <form class="example" action="/action_page.php">
                            <textarea type="text" class="text-white w-100 display-4 " placeholder="Title..."
                                name="search2"></textarea>
                        </form>
                        <h1 class="tell">Tell your story...</h1>
                    </div>
                </div>
            </div>
            <div class="col-md-3 box">
                <div class="row">
                    <div class="col-md-12 pt-2">
                        <h6 class="    f_text py-4">Other Authors</h6>
                    </div>
                </div>
                <div class="row pb-4 ">
                    <div class="col-md-3">
                        <img class="img-comment dropdown-toggle " data-toggle="dropdown"
                            src="./../../assets/image/bloguser3.jpg">

                    </div>
                    <div class="col-md-9">
                        <h4 class="f_text alexmob">Alex Authors</h4>
                        <h6 class="f_text alexmob ">See Profile <img class="img-comment1 dropdown-toggle " data-toggle="dropdown"
                            src="./../../assets/image/Group 355 (1).svg"> </h6>
                    </div>
                </div>
                <div class="row pb-4 ">
                    <div class="col-md-3">
                        <img class="img-comment dropdown-toggle " data-toggle="dropdown"
                            src="./../../assets/image/bloguser3.jpg">

                    </div>
                    <div class="col-md-9">
                        <h4 class="f_text alexmob">Alex Authors</h4>
                        <h6 class="f_text alexmob ">See Profile <img class="img-comment1 dropdown-toggle " data-toggle="dropdown"
                            src="./../../assets/image/Group 355 (1).svg"></h6>
                    </div>
                </div>
                <div class="row pb-4 ">
                    <div class="col-md-3">
                        <img class="img-comment dropdown-toggle " data-toggle="dropdown"
                            src="./../../assets/image/bloguser3.jpg">

                    </div>
                    <div class="col-md-9">
                        <h4 class="f_text alexmob">Alex Authors</h4>
                        <h6 class="f_text alexmob">See Profile<img class="img-comment1 dropdown-toggle " data-toggle="dropdown"
                            src="./../../assets/image/Group 355 (1).svg"> </h6>
                    </div>
                </div>
                <div class="row pb-4 ">
                    <div class="col-md-3">
                        <img class="img-comment dropdown-toggle " data-toggle="dropdown"
                            src="./../../assets/image/bloguser3.jpg">

                    </div>
                    <div class="col-md-9">
                        <h4 class="f_text alexmob">Alex Authors</h4>
                        <h6 class="f_text alexmob ">See Profile<img class="img-comment1 dropdown-toggle " data-toggle="dropdown"
                            src="./../../assets/image/Group 355 (1).svg"> </h6>
                    </div>
                </div>
                <div class="row pb-4 ">
                    <div class="col-md-3">
                        <img class="img-comment dropdown-toggle " data-toggle="dropdown"
                            src="./../../assets/image/bloguser3.jpg">

                    </div>
                    <div class="col-md-9">
                        <h4 class="f_text alexmob">Alex Authors</h4>
                        <h6 class="f_text alexmob ">See Profile<img class="img-comment1 dropdown-toggle " data-toggle="dropdown"
                            src="./../../assets/image/Group 355 (1).svg"> </h6>
                    </div>
                </div>
                <div class="row pb-4 ">
                    <div class="col-md-3">
                        <img class="img-comment dropdown-toggle " data-toggle="dropdown"
                            src="./../../assets/image/bloguser3.jpg">

                    </div>
                    <div class="col-md-9">
                        <h4 class="f_text alexmob">Alex Authors</h4>
                        <h6 class="f_text alexmob ">See Profile <img class="img-comment1 dropdown-toggle " data-toggle="dropdown"
                            src="./../../assets/image/Group 355 (1).svg"></h6>
                    </div>
                </div>
                <div class="row pb-4 ">
                    <div class="col-md-3">
                        <img class="img-comment dropdown-toggle " data-toggle="dropdown"
                            src="./../../assets/image/bloguser3.jpg">

                    </div>
                    <div class="col-md-9">
                        <h4 class="f_text alexmob">Alex Authors</h4>
                        <h6 class="f_text alexmob ">See Profile <img class="img-comment1 dropdown-toggle " data-toggle="dropdown"
                            src="./../../assets/image/Group 355 (1).svg"></h6>
                    </div>
                </div>
                <div class="row pb-4 ">
                    <div class="col-md-3">
                        <img class="img-comment dropdown-toggle " data-toggle="dropdown"
                            src="./../../assets/image/bloguser3.jpg">

                    </div>
                    <div class="col-md-9">
                        <h4 class="f_text alexmob">Alex Authors</h4>
                        <h6 class="f_text alexmob ">See Profile <img class="img-comment1 dropdown-toggle " data-toggle="dropdown"
                            src="./../../assets/image/Group 355 (1).svg"></h6>
                    </div>
                </div>
                <div class="row">
                    <div class="col-md-12 pt-2">
                        <a routerLink="/more-author">   <h6 class="    f_text py-4">More Authors <img class=" dropdown-toggle " data-toggle="dropdown"
                            src="./../../assets/image/Group 355 (1).svg"></h6></a>
                       
                    </div>
                </div>
              
             
                
            </div>
           
        </div>
       
    </div>
</div>

<div class="container cc-container footer ">
   
    <div class="row">
        <div class="col-md-3"></div>
        <div class="col-md-1">
            <a routerLink="/"> <h2 class="text-white mob_t ">Parable <br> Symptoms</h2></a>
        </div>
        <div class="col-md-3 text-white">
            <h2 class=" display-4 mob_t">
                0&nbsp;2&nbsp;.&nbsp;0&nbsp;2&nbsp;.&nbsp;2&nbsp;0&nbsp;2&nbsp;0</h2>
        </div>
        <div class="col-md-2 text-white">
          <h2 class=" display-4 mob_t">
              0&nbsp;2&nbsp;.&nbsp;0&nbsp;2&nbsp;.&nbsp;2&nbsp;0&nbsp;2&nbsp;0</h2>
      </div>
        <div class="col-md-3"></div>
    </div>

    <div class="row">
        <div class="col-md-12">
            <h5 class="text-white text-center">&copy; 2020 Parable Symptom. All right reserved Terms of use privacy
                policy</h5>
        </div>

    </div>
</div>