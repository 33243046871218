<div class="container cc-container" *ngIf="!showMap">
  <div class="row">
    <div class="col-md-2 py-3">
    </div>
    <div class="col-md-4 py-3">
      <form class="example" action="/action_page.php">
        <input type="text" class="text-white" placeholder="Search.." name="search2">

      </form>

    </div>
    <div class="col-md-2 py-3 ">


    </div>
    <div class="col-md-1 py-2 ">


      <div class="row">
        <div class="col-md-12">
          <h5 class="btn  ">
            <!-- <span onclick="document.getElementById('id01').style.display='block'">
                          <h1 class=" text-white sign ">Sign in</h1>
                      </span> -->
            <div class="dropdown">
              <h1 class=" text-white sign dropdown" data-toggle="dropdown">Hi Alex</h1>
              <div class="dropdown-menu">
                <ul class="texx">
                  <a routerLink="/profile">
                    <li class="text-white pt-2" class="dropdown-item"><i class="fa fa-user"
                        aria-hidden="true"></i>&nbsp;&nbsp;Profile</li>
                  </a>
                  <a routerLink="/edit_profile">
                    <li class="text-white pt-2" class="dropdown-item"><i class="fa fa-cog"
                        aria-hidden="true"></i>&nbsp;&nbsp;Setting</li>
                  </a>
                  <a routerLink="/logout">
                    <li class="text-white pt-2" class="dropdown-item"><i class="fa fa-sign-out"
                        aria-hidden="true"></i>&nbsp;&nbsp;Logout</li>
                  </a>
                </ul>
              </div>
            </div>
          </h5>

          <div id="id01" class="w3-modal">
            <div class="w3-modal-content">

              <span onclick="document.getElementById('id01').style.display='none'"
                class="w3-button w3-display-topright "><span class="close fa-3x text-white ">&times;</span></span>
              <h1 class="text-center text-white pt-5 Welcome">Welcome to<br> Parable Symptom</h1>

              <div class="row ">
                <div class="col-md-2 ">


                </div>
                <div class="col-lg-8 ml-2 py-4 ">
                  <button class="btoon p-3 f_text "> <img class="google mb-0" src="./../../assets/activity/google.png"
                      alt=""> &nbsp;Sign in with
                    Google</button>
                  <button class="btoon p-3 mt-3 f_text"> <img class="google mb-0"
                      src="./../../assets/activity/facebook1.png" alt=""> &nbsp;Sign in with
                    Facebook</button>
                  <button class="btoon p-3 mt-3 f_text"> <img class="google mb-0"
                      src="./../../assets/activity/twitter (1).png" alt=""> &nbsp;Sign in with
                    Twitter</button>
                  <button class="btoon p-3 mt-3 f_text"> <img class="google mb-0"
                      src="./../../assets/activity/apple-logo.png" alt=""> &nbsp;Sign in with
                    Apple</button>
                  <button class="btoon p-3 mt-3 f_text"> <img class="google mb-0"
                      src="./../../assets/activity/gmail.png" alt=""> &nbsp;Sign in with
                    Gmail</button>
                  <h4 class="py-5 f_text ">Click "sign in" to Agree to Medium's Terms of Service and
                    Acknowledge That Medium's Privacy Policy Applies To you.</h4>
                </div>
                <div class="col-md-1 ">


                </div>
              </div>


            </div>
          </div>
        </div>
      </div>
    </div>
    <div class="col-md-3 py-2">


      <div class="row ">
        <div class="col-md-12">
          <h5 class="btn c_mob  ">
            <span onclick="document.getElementById('id02').style.display='block'">
              <a routerLink="/create_blog">
                <h1 class="text-white sign creat "> Create Blog <img src="./../../assets/image/Group 35.svg" alt=""> </h1>
              </a>
            </span>
          </h5>

          <div id="id02" class="w3-modal">
            <div class="w3-modal-content">

              <span onclick="document.getElementById('id02').style.display='none'"
                class="w3-button w3-display-topright "><span class="close fa-3x text-white ">&times;</span></span>
              <h1 class="text-center text-white pt-5 Welcome">Welcome to<br> Parable Symptom</h1>

              <div class="row ">
                <div class="col-md-2 ">


                </div>
                <div class="col-lg-8 ml-2 py-4 ">
                  <button class="btoon p-3 f_text "> <img class="google mb-0" src="./../../assets/activity/google.png"
                      alt=""> &nbsp;Sign in with
                    Google</button>
                  <button class="btoon p-3 mt-3 f_text"> <img class="google mb-0"
                      src="./../../assets/activity/facebook1.png" alt=""> &nbsp;Sign in with
                    Facebook</button>
                  <button class="btoon p-3 mt-3 f_text"> <img class="google mb-0"
                      src="./../../assets/activity/twitter (1).png" alt=""> &nbsp;Sign in with
                    Twitter</button>
                  <button class="btoon p-3 mt-3 f_text"> <img class="google mb-0"
                      src="./../../assets/activity/apple-logo.png" alt=""> &nbsp;Sign in with
                    Apple</button>
                  <button class="btoon p-3 mt-3 f_text"> <img class="google mb-0"
                      src="./../../assets/activity/gmail.png" alt=""> &nbsp;Sign in with
                    Gmail</button>
                  <h4 class="py-5 f_text">Click "sign in" to Agree to Medium's Terms of Service and
                    Acknowledge That Medium's Privacy Policy Applies To you.</h4>
                </div>
                <div class="col-md-1 ">


                </div>
              </div>

            </div>
          </div>
        </div>
      </div>
    </div>

  </div>


</div>

<!-- <div id="regions_div" style="width: 100%; height: 500px;"     (click)="itemSelected($event)" ></div> -->

<div class="0  " #imgTarget>
  
  <div id="myCarousel" class="carousel slide" *ngIf="!showMap" data-ride="">
    <div class="carousel-inner  ">
      <div class="item active  ">
        
        <section class="section ">
          <div class="wall ">
            <div class="v-boxes deshbord ">
              
              <div class="box">
                <a routerLink="/publish">
                <span class="tooltip text-black"><span class="textroted">Welcome willisom Chandler to Roc Nation Sport </span></span>
                  
                <div class="box-face">
              
                  <div class="box-text"> <img class="image11 " src="./../../assets/image/bloguser3.jpg"></div>
                </div>
</a>
              </div>
              <div class="box">
                <a routerLink="/publish">
                <span class="tooltip text-black"><span class="textroted">Welcome willisom Chandler to Roc Nation Sport </span></span>
                  
                <div class="box-face">
              
                  <div class="box-text"> <img class="image11 " src="./../../assets/image/bloguser3.jpg"></div>
                </div>
</a>
              </div>
              <div class="box">
                <a routerLink="/publish">
                <span class="tooltip text-black"><span class="textroted">Welcome willisom Chandler to Roc Nation Sport </span></span>
                  
                <div class="box-face">
              
                  <div class="box-text"> <img class="image11 " src="./../../assets/image/bloguser3.jpg"></div>
                </div>
</a>
              </div>
              <div class="box">
                <a routerLink="/publish">
                <span class="tooltip text-black"><span class="textroted">Welcome willisom Chandler to Roc Nation Sport </span></span>
                  
                <div class="box-face">
              
                  <div class="box-text"> <img class="image11 " src="./../../assets/image/bloguser3.jpg"></div>
                </div>
</a>
              </div>
            </div>
            <div class="v-boxes second deshbord">
              <div class="box">
                <a routerLink="/publish">
                <span class="tooltip text-black"><span class="textroted">Welcome willisom Chandler to Roc Nation Sport </span></span>
                  
                <div class="box-face">
              
                  <div class="box-text"> <img class="image11 " src="./../../assets/image/bloguser3.jpg"></div>
                </div>
</a>
              </div>
              <div class="box">
                <a routerLink="/publish">
                <span class="tooltip text-black"><span class="textroted">Welcome willisom Chandler to Roc Nation Sport </span></span>
                  
                <div class="box-face">
              
                  <div class="box-text"> <img class="image11 " src="./../../assets/image/bloguser3.jpg"></div>
                </div>
</a>
              </div>
              <div class="box">
                <a routerLink="/publish">
                <span class="tooltip text-black"><span class="textroted">Welcome willisom Chandler to Roc Nation Sport </span></span>
                  
                <div class="box-face">
              
                  <div class="box-text"> <img class="image11 " src="./../../assets/image/bloguser3.jpg"></div>
                </div>
</a>
              </div>
            </div>

            <div class="v-boxes ">
              <div class="box">
                <a routerLink="/publish">
                <span class="tooltip text-black"><span class="textroted">Welcome willisom Chandler to Roc Nation Sport </span></span>
                  
                <div class="box-face">
              
                  <div class="box-text"> <img class="image11 " src="./../../assets/image/bloguser3.jpg"></div>
                </div>
</a>
              </div>
              <div class="box">
                <a routerLink="/publish">
                <span class="tooltip text-black"><span class="textroted">Welcome willisom Chandler to Roc Nation Sport </span></span>
                  
                <div class="box-face">
                  <div class="box-text"> <img class="image11 " src="./../../assets/image/bloguser3.jpg"></div>
                </div>
</a>
              </div>
              <div class="box">
                <a routerLink="/publish">
                <span class="tooltip text-black"><span class="textroted">Welcome willisom Chandler to Roc Nation Sport </span></span>
                  
                <div class="box-face">
              
                  <div class="box-text"> <img class="image11 " src="./../../assets/image/bloguser3.jpg"></div>
                </div>
</a>
              </div>
              <div class="box">
                <a routerLink="/publish">
                <span class="tooltip text-black"><span class="textroted">Welcome willisom Chandler to Roc Nation Sport </span></span>
                  
                <div class="box-face">
              
                  <div class="box-text"> <img class="image11 " src="./../../assets/image/bloguser3.jpg"></div>
                </div>
</a>
              </div>
            </div>




            <div class="v-boxes second ">
              <div class="box">
                <a routerLink="/publish">
                <span class="tooltip text-black"><span class="textroted">Welcome willisom Chandler to Roc Nation Sport </span></span>
                  
                <div class="box-face">
              
                  <div class="box-text"> <img class="image11 " src="./../../assets/image/bloguser3.jpg"></div>
                </div>
</a>
              </div>
              <div class="box">
                <a routerLink="/publish">
                <span class="tooltip text-black"><span class="textroted">Welcome willisom Chandler to Roc Nation Sport </span></span>
                  
                <div class="box-face">
              
                  <div class="box-text"> <img class="image11 " src="./../../assets/image/bloguser3.jpg"></div>
                </div>
</a>
              </div>
              <div class="box">
                <a routerLink="/publish">
                <span class="tooltip text-black"><span class="textroted">Welcome willisom Chandler to Roc Nation Sport </span></span>
                  
                <div class="box-face">
              
                  <div class="box-text"> <img class="image11 " src="./../../assets/image/bloguser3.jpg"></div>
                </div>
</a>
              </div>
            </div>

            <div class="v-boxes ">
              <div class="box">
                <a routerLink="/publish">
                <span class="tooltip text-black"><span class="textroted">Welcome willisom Chandler to Roc Nation Sport </span></span>
                  
                <div class="box-face">
              
                  <div class="box-text"> <img class="image11 " src="./../../assets/image/bloguser3.jpg"></div>
                </div>
</a>
              </div>






              <div class="box">

                <div class=" ">
                  <div class=""><img class="image11 rotated " src="./../../assets/image/Mask Group 39.png"
                      (click)="clickData(target)"></div>
                </div>

              </div>
              <div class="box">

                <div class=" ">
                  <div class=""><img class="image11 rotated " src="./../../assets/image/Mask Group 38.png"
                      (click)="clickData(target)"></div>
                </div>

              </div>
              <div class="box">
                <a routerLink="/publish">
                <span class="tooltip text-black"><span class="textroted">Welcome willisom Chandler to Roc Nation Sport </span></span>
                  
                <div class="box-face">
              
                  <div class="box-text"> <img class="image11 " src="./../../assets/image/bloguser3.jpg"></div>
                </div>
</a>
              </div>
            </div>

            <div class="v-boxes second">
              <div class="box">
                <a routerLink="/publish">
                <span class="tooltip text-black"><span class="textroted">Welcome willisom Chandler to Roc Nation Sport </span></span>
                  
                <div class="box-face">
              
                  <div class="box-text"> <img class="image11 " src="./../../assets/image/bloguser3.jpg"></div>
                </div>
</a>
              </div>
              <div class="box">

                <div class=" ">
                  <div class=""><img class="image11 rotated " src="./../../assets/image/Mask Group 37.png"
                      (click)="clickData(target)"></div>
                </div>

              </div>
              <div class="box">
                <a routerLink="/publish">
                <span class="tooltip text-black"><span class="textroted">Welcome willisom Chandler to Roc Nation Sport </span></span>
                  
                <div class="box-face">
              
                  <div class="box-text"> <img class="image11 " src="./../../assets/image/bloguser3.jpg"></div>
                </div>
</a>
              </div>
            </div>
            <div class="v-boxes ">
              <div class="box">
                <a routerLink="/publish">
                <span class="tooltip text-black"><span class="textroted">Welcome willisom Chandler to Roc Nation Sport </span></span>
                  
                <div class="box-face">
              
                  <div class="box-text"> <img class="image11 " src="./../../assets/image/bloguser3.jpg"></div>
                </div>
</a>
              </div>
              <div class="box">

                <div class=" ">
                  <div class=""><img class="image11 rotated " src="./../../assets/image/Mask Group 36.png"
                      (click)="clickData(target)"></div>
                </div>

              </div>
              <div class="box">

                <div class=" ">
                  <div class=""><img class="image11 rotated " src="./../../assets/image/Mask Group 39.png"
                      (click)="clickData(target)"></div>
                </div>

              </div>
              <div class="box">
                <a routerLink="/publish">
                <span class="tooltip text-black"><span class="textroted">Welcome willisom Chandler to Roc Nation Sport </span></span>
                  
                <div class="box-face">
              
                  <div class="box-text"> <img class="image11 " src="./../../assets/image/bloguser3.jpg"></div>
                </div>
</a>
              </div>
            </div>
            <div class="v-boxes second ">
              <div class="box">
                <a routerLink="/publish">
                <span class="tooltip text-black"><span class="textroted">Welcome willisom Chandler to Roc Nation Sport </span></span>
                  
                <div class="box-face">
              
                  <div class="box-text"> <img class="image11 " src="./../../assets/image/bloguser3.jpg"></div>
                </div>
</a>
              </div>
              <div class="box">
                <a routerLink="/publish">
                <span class="tooltip text-black"><span class="textroted">Welcome willisom Chandler to Roc Nation Sport </span></span>
                  
                <div class="box-face">
              
                  <div class="box-text"> <img class="image11 " src="./../../assets/image/bloguser3.jpg"></div>
                </div>
</a>
              </div>
              <div class="box">

                <div class=" ">
                  <div class=""><img class="image11 rotated " src="./../../assets/image/Mask Group 39.png"
                      (click)="clickData(target)"></div>
                </div>

              </div>
            </div>
            <div class="v-boxes ">
              <div class="box">
                <a routerLink="/publish">
                <span class="tooltip text-black"><span class="textroted">Welcome willisom Chandler to Roc Nation Sport </span></span>
                  
                <div class="box-face">
              
                  <div class="box-text"> <img class="image11 " src="./../../assets/image/bloguser3.jpg"></div>
                </div>
</a>
              </div>
              <div class="box">
                <a routerLink="/publish">
                <span class="tooltip text-black"><span class="textroted">Welcome willisom Chandler to Roc Nation Sport </span></span>
                  
                <div class="box-face">
              
                  <div class="box-text"> <img class="image11 " src="./../../assets/image/bloguser3.jpg"></div>
                </div>
</a>
              </div>
              <div class="box">
                <a routerLink="/publish">
                <span class="tooltip text-black"><span class="textroted">Welcome willisom Chandler to Roc Nation Sport </span></span>
                  
                <div class="box-face">
              
                  <div class="box-text"> <img class="image11 " src="./../../assets/image/bloguser3.jpg"></div>
                </div>
</a>
              </div>
              <div class="box">
                <a routerLink="/publish">
                <span class="tooltip text-black"><span class="textroted">Welcome willisom Chandler to Roc Nation Sport </span></span>
                  
                <div class="box-face">
              
                  <div class="box-text"> <img class="image11 " src="./../../assets/image/bloguser3.jpg"></div>
                </div>
</a>
              </div>
            </div>
            <div class="v-boxes second deshbord">
              <div class="box">
                <a routerLink="/publish">
                <span class="tooltip text-black"><span class="textroted">Welcome willisom Chandler to Roc Nation Sport </span></span>
                  
                <div class="box-face">
              
                  <div class="box-text"> <img class="image11 " src="./../../assets/image/bloguser3.jpg"></div>
                </div>
</a>
              </div>
              <div class="box">
                <a routerLink="/publish">
                <span class="tooltip text-black"><span class="textroted">Welcome willisom Chandler to Roc Nation Sport </span></span>
                  
                <div class="box-face">
              
                  <div class="box-text"> <img class="image11 " src="./../../assets/image/bloguser3.jpg"></div>
                </div>
</a>
              </div>
              <div class="box">
                <a routerLink="/publish">
                <span class="tooltip text-black"><span class="textroted">Welcome willisom Chandler to Roc Nation Sport </span></span>
                  
                <div class="box-face">
              
                  <div class="box-text"> <img class="image11 " src="./../../assets/image/bloguser3.jpg"></div>
                </div>
</a>
              </div>
            </div>
            <div class="v-boxes deshbord">
              <div class="box">
                <a routerLink="/publish">
                <span class="tooltip text-black"><span class="textroted">Welcome willisom Chandler to Roc Nation Sport </span></span>
                  
                <div class="box-face">
              
                  <div class="box-text"> <img class="image11 " src="./../../assets/image/bloguser3.jpg"></div>
                </div>
</a>
              </div>
              <div class="box">
                <a routerLink="/publish">
                <span class="tooltip text-black"><span class="textroted">Welcome willisom Chandler to Roc Nation Sport </span></span>
                  
                <div class="box-face">
              
                  <div class="box-text"> <img class="image11 " src="./../../assets/image/bloguser3.jpg"></div>
                </div>
</a>
              </div>
              <div class="box">
                <a routerLink="/publish">
                <span class="tooltip text-black"><span class="textroted">Welcome willisom Chandler to Roc Nation Sport </span></span>
                  
                <div class="box-face">
              
                  <div class="box-text"> <img class="image11 " src="./../../assets/image/bloguser3.jpg"></div>
                </div>
</a>
              </div>
              <div class="box">
                <a routerLink="/publish">
                <span class="tooltip text-black"><span class="textroted">Welcome willisom Chandler to Roc Nation Sport </span></span>
                  
                <div class="box-face">
              
                  <div class="box-text"> <img class="image11 " src="./../../assets/image/bloguser3.jpg"></div>
                </div>
</a>
              </div>
            </div>
            <div class="v-boxes second deshbord">
              <div class="box">
                <a routerLink="/publish">
                <span class="tooltip text-black"><span class="textroted">Welcome willisom Chandler to Roc Nation Sport </span></span>
                  
                <div class="box-face">
              
                  <div class="box-text"> <img class="image11 " src="./../../assets/image/bloguser3.jpg"></div>
                </div>
</a>
              </div>
              <div class="box">
                <a routerLink="/publish">
                <span class="tooltip text-black"><span class="textroted">Welcome willisom Chandler to Roc Nation Sport </span></span>
                  
                <div class="box-face">
              
                  <div class="box-text"> <img class="image11 " src="./../../assets/image/bloguser3.jpg"></div>
                </div>
</a>
              </div>
              <div class="box">
                <a routerLink="/publish">
                <span class="tooltip text-black"><span class="textroted">Welcome willisom Chandler to Roc Nation Sport </span></span>
                  
                <div class="box-face">
              
                  <div class="box-text"> <img class="image11 " src="./../../assets/image/bloguser3.jpg"></div>
                </div>
</a>
              </div>
            </div>
            <div class="v-boxes deshbord">
              <div class="box">
                <a routerLink="/publish">
                <span class="tooltip text-black"><span class="textroted">Welcome willisom Chandler to Roc Nation Sport </span></span>
                  
                <div class="box-face">
              
                  <div class="box-text"> <img class="image11 " src="./../../assets/image/bloguser3.jpg"></div>
                </div>
</a>
              </div>
              <div class="box">
                <a routerLink="/publish">
                <span class="tooltip text-black"><span class="textroted">Welcome willisom Chandler to Roc Nation Sport </span></span>
                  
                <div class="box-face">
              
                  <div class="box-text"> <img class="image11 " src="./../../assets/image/bloguser3.jpg"></div>
                </div>
</a>
              </div>
              <div class="box">
                <a routerLink="/publish">
                <span class="tooltip text-black"><span class="textroted">Welcome willisom Chandler to Roc Nation Sport </span></span>
                  
                <div class="box-face">
              
                  <div class="box-text"> <img class="image11 " src="./../../assets/image/bloguser3.jpg"></div>
                </div>
</a>
              </div>
              <div class="box">
                <a routerLink="/publish">
                <span class="tooltip text-black"><span class="textroted">Welcome willisom Chandler to Roc Nation Sport </span></span>
                  
                <div class="box-face">
              
                  <div class="box-text"> <img class="image11 " src="./../../assets/image/bloguser3.jpg"></div>
                </div>
</a>
              </div>
            </div>
            <div class="v-boxes second">
              <div class="box">
                <a routerLink="/publish">
                <span class="tooltip text-black"><span class="textroted">Welcome willisom Chandler to Roc Nation Sport </span></span>
                  
                <div class="box-face">
              
                  <div class="box-text"> <img class="image11 " src="./../../assets/image/bloguser3.jpg"></div>
                </div>
</a>
              </div>
              <div class="box">
                <a routerLink="/publish">
                <span class="tooltip text-black"><span class="textroted">Welcome willisom Chandler to Roc Nation Sport </span></span>
                  
                <div class="box-face">
              
                  <div class="box-text"> <img class="image11 " src="./../../assets/image/bloguser3.jpg"></div>
                </div>
</a>
              </div>
              <div class="box">
                <a routerLink="/publish">
                <span class="tooltip text-black"><span class="textroted">Welcome willisom Chandler to Roc Nation Sport </span></span>
                  
                <div class="box-face">
              
                  <div class="box-text"> <img class="image11 " src="./../../assets/image/bloguser3.jpg"></div>
                </div>
</a>
              </div>
            </div>
            <div class="v-boxes deshbord">
              <div class="box">
                <a routerLink="/publish">
                <span class="tooltip text-black"><span class="textroted">Welcome willisom Chandler to Roc Nation Sport </span></span>
                  
                <div class="box-face">
              
                  <div class="box-text"> <img class="image11 " src="./../../assets/image/bloguser3.jpg"></div>
                </div>
</a>
              </div>
              <div class="box">
                <a routerLink="/publish">
                <span class="tooltip text-black"><span class="textroted">Welcome willisom Chandler to Roc Nation Sport </span></span>
                  
                <div class="box-face">
              
                  <div class="box-text"> <img class="image11 " src="./../../assets/image/bloguser3.jpg"></div>
                </div>
</a>
              </div>
              <div class="box">
                <a routerLink="/publish">
                <span class="tooltip text-black"><span class="textroted">Welcome willisom Chandler to Roc Nation Sport </span></span>
                  
                <div class="box-face">
              
                  <div class="box-text"> <img class="image11 " src="./../../assets/image/bloguser3.jpg"></div>
                </div>
</a>
              </div>
              <div class="box">
                <a routerLink="/publish">
                <span class="tooltip text-black"><span class="textroted">Welcome willisom Chandler to Roc Nation Sport </span></span>
                  
                <div class="box-face">
              
                  <div class="box-text"> <img class="image11 " src="./../../assets/image/bloguser3.jpg"></div>
                </div>
</a>
              </div>
            </div>
            <div class="v-boxes second deshbord">
              <div class="box">
                <a routerLink="/publish">
                <span class="tooltip text-black"><span class="textroted">Welcome willisom Chandler to Roc Nation Sport </span></span>
                  
                <div class="box-face">
              
                  <div class="box-text"> <img class="image11 " src="./../../assets/image/bloguser3.jpg"></div>
                </div>
</a>
              </div>
              <div class="box">
                <a routerLink="/publish">
                <span class="tooltip text-black"><span class="textroted">Welcome willisom Chandler to Roc Nation Sport </span></span>
                  
                <div class="box-face">
              
                  <div class="box-text"> <img class="image11 " src="./../../assets/image/bloguser3.jpg"></div>
                </div>
</a>
              </div>
              <div class="box">
                <a routerLink="/publish">
                <span class="tooltip text-black"><span class="textroted">Welcome willisom Chandler to Roc Nation Sport </span></span>
                  
                <div class="box-face">
              
                  <div class="box-text"> <img class="image11 " src="./../../assets/image/bloguser3.jpg"></div>
                </div>
</a>
              </div>
            </div>
            <div class="v-boxes deshbord">
              <div class="box">
                <a routerLink="/publish">
                <span class="tooltip text-black"><span class="textroted">Welcome willisom Chandler to Roc Nation Sport </span></span>
                  
                <div class="box-face">
              
                  <div class="box-text"> <img class="image11 " src="./../../assets/image/bloguser3.jpg"></div>
                </div>
</a>
              </div>
              <div class="box">
                <a routerLink="/publish">
                <span class="tooltip text-black"><span class="textroted">Welcome willisom Chandler to Roc Nation Sport </span></span>
                  
                <div class="box-face">
              
                  <div class="box-text"> <img class="image11 " src="./../../assets/image/bloguser3.jpg"></div>
                </div>
</a>
              </div>
              <div class="box">
                <a routerLink="/publish">
                <span class="tooltip text-black"><span class="textroted">Welcome willisom Chandler to Roc Nation Sport </span></span>
                  
                <div class="box-face">
              
                  <div class="box-text"> <img class="image11 " src="./../../assets/image/bloguser3.jpg"></div>
                </div>
</a>
              </div>
              <div class="box">
                <a routerLink="/publish">
                <span class="tooltip text-black"><span class="textroted">Welcome willisom Chandler to Roc Nation Sport </span></span>
                  
                <div class="box-face">
              
                  <div class="box-text"> <img class="image11 " src="./../../assets/image/bloguser3.jpg"></div>
                </div>
</a>
              </div>
            </div>
            <div class="v-boxes second deshbord">
              <div class="box">
                <a routerLink="/publish">
                <span class="tooltip text-black"><span class="textroted">Welcome willisom Chandler to Roc Nation Sport </span></span>
                  
                <div class="box-face">
              
                  <div class="box-text"> <img class="image11 " src="./../../assets/image/bloguser3.jpg"></div>
                </div>
</a>
              </div>
              <div class="box">
                <a routerLink="/publish">
                <span class="tooltip text-black"><span class="textroted">Welcome willisom Chandler to Roc Nation Sport </span></span>
                  
                <div class="box-face">
              
                  <div class="box-text"> <img class="image11 " src="./../../assets/image/bloguser3.jpg"></div>
                </div>
</a>
              </div>
              <div class="box">
                <a routerLink="/publish">
                <span class="tooltip text-black"><span class="textroted">Welcome willisom Chandler to Roc Nation Sport </span></span>
                  
                <div class="box-face">
              
                  <div class="box-text"> <img class="image11 " src="./../../assets/image/bloguser3.jpg"></div>
                </div>
</a>
              </div>
            </div>

            <div class="v-boxes deshbord">
              <div class="box">
                <a routerLink="/publish">
                <span class="tooltip text-black"><span class="textroted">Welcome willisom Chandler to Roc Nation Sport </span></span>
                  
                <div class="box-face">
              
                  <div class="box-text"> <img class="image11 " src="./../../assets/image/bloguser3.jpg"></div>
                </div>
</a>
              </div>
              <div class="box">
                <a routerLink="/publish">
                <span class="tooltip text-black"><span class="textroted">Welcome willisom Chandler to Roc Nation Sport </span></span>
                  
                <div class="box-face">
              
                  <div class="box-text"> <img class="image11 " src="./../../assets/image/bloguser3.jpg"></div>
                </div>
</a>
              </div>
              <div class="box">
                <a routerLink="/publish">
                <span class="tooltip text-black"><span class="textroted">Welcome willisom Chandler to Roc Nation Sport </span></span>
                  
                <div class="box-face">
              
                  <div class="box-text"> <img class="image11 " src="./../../assets/image/bloguser3.jpg"></div>
                </div>
</a>
              </div>
              <div class="box">
                <a routerLink="/publish">
                <span class="tooltip text-black"><span class="textroted">Welcome willisom Chandler to Roc Nation Sport </span></span>
                  
                <div class="box-face">
              
                  <div class="box-text"> <img class="image11 " src="./../../assets/image/bloguser3.jpg"></div>
                </div>
</a>
              </div>
            </div>





          </div>
        </section>


      
      </div>
      <div class="item">
        <section class="section ">
          <div class="wall ">
            <div class="v-boxes deshbord ">
              
              <div class="box">
                <a routerLink="/publish">
                <span class="tooltip text-black"><span class="textroted">Welcome willisom Chandler to Roc Nation Sport </span></span>
                  
                <div class="box-face">
              
                  <div class="box-text"> <img class="image11 " src="./../../assets/image/bloguser3.jpg"></div>
                </div>
</a>
              </div>
              <div class="box">
                <a routerLink="/publish">
                <span class="tooltip text-black"><span class="textroted">Welcome willisom Chandler to Roc Nation Sport </span></span>
                  
                <div class="box-face">
              
                  <div class="box-text"> <img class="image11 " src="./../../assets/image/bloguser3.jpg"></div>
                </div>
</a>
              </div>
              <div class="box">
                <a routerLink="/publish">
                <span class="tooltip text-black"><span class="textroted">Welcome willisom Chandler to Roc Nation Sport </span></span>
                  
                <div class="box-face">
              
                  <div class="box-text"> <img class="image11 " src="./../../assets/image/bloguser3.jpg"></div>
                </div>
</a>
              </div>
              <div class="box">
                <a routerLink="/publish">
                <span class="tooltip text-black"><span class="textroted">Welcome willisom Chandler to Roc Nation Sport </span></span>
                  
                <div class="box-face">
              
                  <div class="box-text"> <img class="image11 " src="./../../assets/image/bloguser3.jpg"></div>
                </div>
</a>
              </div>
            </div>
            <div class="v-boxes second deshbord">
              <div class="box">
                <a routerLink="/publish">
                <span class="tooltip text-black"><span class="textroted">Welcome willisom Chandler to Roc Nation Sport </span></span>
                  
                <div class="box-face">
              
                  <div class="box-text"> <img class="image11 " src="./../../assets/image/bloguser3.jpg"></div>
                </div>
</a>
              </div>
              <div class="box">
                <a routerLink="/publish">
                <span class="tooltip text-black"><span class="textroted">Welcome willisom Chandler to Roc Nation Sport </span></span>
                  
                <div class="box-face">
              
                  <div class="box-text"> <img class="image11 " src="./../../assets/image/bloguser3.jpg"></div>
                </div>
</a>
              </div>
              <div class="box">
                <a routerLink="/publish">
                <span class="tooltip text-black"><span class="textroted">Welcome willisom Chandler to Roc Nation Sport </span></span>
                  
                <div class="box-face">
              
                  <div class="box-text"> <img class="image11 " src="./../../assets/image/bloguser3.jpg"></div>
                </div>
</a>
              </div>
            </div>

            <div class="v-boxes ">
              <div class="box">
                <a routerLink="/publish">
                <span class="tooltip text-black"><span class="textroted">Welcome willisom Chandler to Roc Nation Sport </span></span>
                  
                <div class="box-face">
              
                  <div class="box-text"> <img class="image11 " src="./../../assets/image/bloguser3.jpg"></div>
                </div>
</a>
              </div>
              <div class="box">
                <a routerLink="/publish">
                <span class="tooltip text-black"><span class="textroted">Welcome willisom Chandler to Roc Nation Sport </span></span>
                  
                <div class="box-face">
                  <div class="box-text"> <img class="image11 " src="./../../assets/image/bloguser3.jpg"></div>
                </div>
</a>
              </div>
              <div class="box">
                <a routerLink="/publish">
                <span class="tooltip text-black"><span class="textroted">Welcome willisom Chandler to Roc Nation Sport </span></span>
                  
                <div class="box-face">
              
                  <div class="box-text"> <img class="image11 " src="./../../assets/image/bloguser3.jpg"></div>
                </div>
</a>
              </div>
              <div class="box">
                <a routerLink="/publish">
                <span class="tooltip text-black"><span class="textroted">Welcome willisom Chandler to Roc Nation Sport </span></span>
                  
                <div class="box-face">
              
                  <div class="box-text"> <img class="image11 " src="./../../assets/image/bloguser3.jpg"></div>
                </div>
</a>
              </div>
            </div>




            <div class="v-boxes second ">
              <div class="box">
                <a routerLink="/publish">
                <span class="tooltip text-black"><span class="textroted">Welcome willisom Chandler to Roc Nation Sport </span></span>
                  
                <div class="box-face">
              
                  <div class="box-text"> <img class="image11 " src="./../../assets/image/bloguser3.jpg"></div>
                </div>
</a>
              </div>
              <div class="box">
                <a routerLink="/publish">
                <span class="tooltip text-black"><span class="textroted">Welcome willisom Chandler to Roc Nation Sport </span></span>
                  
                <div class="box-face">
              
                  <div class="box-text"> <img class="image11 " src="./../../assets/image/bloguser3.jpg"></div>
                </div>
</a>
              </div>
              <div class="box">
                <a routerLink="/publish">
                <span class="tooltip text-black"><span class="textroted">Welcome willisom Chandler to Roc Nation Sport </span></span>
                  
                <div class="box-face">
              
                  <div class="box-text"> <img class="image11 " src="./../../assets/image/bloguser3.jpg"></div>
                </div>
</a>
              </div>
            </div>

            <div class="v-boxes ">
              <div class="box">
                <a routerLink="/publish">
                <span class="tooltip text-black"><span class="textroted">Welcome willisom Chandler to Roc Nation Sport </span></span>
                  
                <div class="box-face">
              
                  <div class="box-text"> <img class="image11 " src="./../../assets/image/bloguser3.jpg"></div>
                </div>
</a>
              </div>






              <div class="box">

                <div class=" ">
                  <div class=""><img class="image11 rotated " src="./../../assets/image/Mask Group 39.png"
                      (click)="clickData(target)"></div>
                </div>

              </div>
              <div class="box">

                <div class=" ">
                  <div class=""><img class="image11 rotated " src="./../../assets/image/Mask Group 38.png"
                      (click)="clickData(target)"></div>
                </div>

              </div>
              <div class="box">
                <a routerLink="/publish">
                <span class="tooltip text-black"><span class="textroted">Welcome willisom Chandler to Roc Nation Sport </span></span>
                  
                <div class="box-face">
              
                  <div class="box-text"> <img class="image11 " src="./../../assets/image/bloguser3.jpg"></div>
                </div>
</a>
              </div>
            </div>

            <div class="v-boxes second">
              <div class="box">
                <a routerLink="/publish">
                <span class="tooltip text-black"><span class="textroted">Welcome willisom Chandler to Roc Nation Sport </span></span>
                  
                <div class="box-face">
              
                  <div class="box-text"> <img class="image11 " src="./../../assets/image/bloguser3.jpg"></div>
                </div>
</a>
              </div>
              <div class="box">

                <div class=" ">
                  <div class=""><img class="image11 rotated " src="./../../assets/image/Mask Group 37.png"
                      (click)="clickData(target)"></div>
                </div>

              </div>
              <div class="box">
                <a routerLink="/publish">
                <span class="tooltip text-black"><span class="textroted">Welcome willisom Chandler to Roc Nation Sport </span></span>
                  
                <div class="box-face">
              
                  <div class="box-text"> <img class="image11 " src="./../../assets/image/bloguser3.jpg"></div>
                </div>
</a>
              </div>
            </div>
            <div class="v-boxes ">
              <div class="box">
                <a routerLink="/publish">
                <span class="tooltip text-black"><span class="textroted">Welcome willisom Chandler to Roc Nation Sport </span></span>
                  
                <div class="box-face">
              
                  <div class="box-text"> <img class="image11 " src="./../../assets/image/bloguser3.jpg"></div>
                </div>
</a>
              </div>
              <div class="box">

                <div class=" ">
                  <div class=""><img class="image11 rotated " src="./../../assets/image/Mask Group 36.png"
                      (click)="clickData(target)"></div>
                </div>

              </div>
              <div class="box">

                <div class=" ">
                  <div class=""><img class="image11 rotated " src="./../../assets/image/Mask Group 39.png"
                      (click)="clickData(target)"></div>
                </div>

              </div>
              <div class="box">
                <a routerLink="/publish">
                <span class="tooltip text-black"><span class="textroted">Welcome willisom Chandler to Roc Nation Sport </span></span>
                  
                <div class="box-face">
              
                  <div class="box-text"> <img class="image11 " src="./../../assets/image/bloguser3.jpg"></div>
                </div>
</a>
              </div>
            </div>
            <div class="v-boxes second ">
              <div class="box">
                <a routerLink="/publish">
                <span class="tooltip text-black"><span class="textroted">Welcome willisom Chandler to Roc Nation Sport </span></span>
                  
                <div class="box-face">
              
                  <div class="box-text"> <img class="image11 " src="./../../assets/image/bloguser3.jpg"></div>
                </div>
</a>
              </div>
              <div class="box">
                <a routerLink="/publish">
                <span class="tooltip text-black"><span class="textroted">Welcome willisom Chandler to Roc Nation Sport </span></span>
                  
                <div class="box-face">
              
                  <div class="box-text"> <img class="image11 " src="./../../assets/image/bloguser3.jpg"></div>
                </div>
</a>
              </div>
              <div class="box">

                <div class=" ">
                  <div class=""><img class="image11 rotated " src="./../../assets/image/Mask Group 39.png"
                      (click)="clickData(target)"></div>
                </div>

              </div>
            </div>
            <div class="v-boxes ">
              <div class="box">
                <a routerLink="/publish">
                <span class="tooltip text-black"><span class="textroted">Welcome willisom Chandler to Roc Nation Sport </span></span>
                  
                <div class="box-face">
              
                  <div class="box-text"> <img class="image11 " src="./../../assets/image/bloguser3.jpg"></div>
                </div>
</a>
              </div>
              <div class="box">
                <a routerLink="/publish">
                <span class="tooltip text-black"><span class="textroted">Welcome willisom Chandler to Roc Nation Sport </span></span>
                  
                <div class="box-face">
              
                  <div class="box-text"> <img class="image11 " src="./../../assets/image/bloguser3.jpg"></div>
                </div>
</a>
              </div>
              <div class="box">
                <a routerLink="/publish">
                <span class="tooltip text-black"><span class="textroted">Welcome willisom Chandler to Roc Nation Sport </span></span>
                  
                <div class="box-face">
              
                  <div class="box-text"> <img class="image11 " src="./../../assets/image/bloguser3.jpg"></div>
                </div>
</a>
              </div>
              <div class="box">
                <a routerLink="/publish">
                <span class="tooltip text-black"><span class="textroted">Welcome willisom Chandler to Roc Nation Sport </span></span>
                  
                <div class="box-face">
              
                  <div class="box-text"> <img class="image11 " src="./../../assets/image/bloguser3.jpg"></div>
                </div>
</a>
              </div>
            </div>
            <div class="v-boxes second deshbord">
              <div class="box">
                <a routerLink="/publish">
                <span class="tooltip text-black"><span class="textroted">Welcome willisom Chandler to Roc Nation Sport </span></span>
                  
                <div class="box-face">
              
                  <div class="box-text"> <img class="image11 " src="./../../assets/image/bloguser3.jpg"></div>
                </div>
</a>
              </div>
              <div class="box">
                <a routerLink="/publish">
                <span class="tooltip text-black"><span class="textroted">Welcome willisom Chandler to Roc Nation Sport </span></span>
                  
                <div class="box-face">
              
                  <div class="box-text"> <img class="image11 " src="./../../assets/image/bloguser3.jpg"></div>
                </div>
</a>
              </div>
              <div class="box">
                <a routerLink="/publish">
                <span class="tooltip text-black"><span class="textroted">Welcome willisom Chandler to Roc Nation Sport </span></span>
                  
                <div class="box-face">
              
                  <div class="box-text"> <img class="image11 " src="./../../assets/image/bloguser3.jpg"></div>
                </div>
</a>
              </div>
            </div>
            <div class="v-boxes deshbord">
              <div class="box">
                <a routerLink="/publish">
                <span class="tooltip text-black"><span class="textroted">Welcome willisom Chandler to Roc Nation Sport </span></span>
                  
                <div class="box-face">
              
                  <div class="box-text"> <img class="image11 " src="./../../assets/image/bloguser3.jpg"></div>
                </div>
</a>
              </div>
              <div class="box">
                <a routerLink="/publish">
                <span class="tooltip text-black"><span class="textroted">Welcome willisom Chandler to Roc Nation Sport </span></span>
                  
                <div class="box-face">
              
                  <div class="box-text"> <img class="image11 " src="./../../assets/image/bloguser3.jpg"></div>
                </div>
</a>
              </div>
              <div class="box">
                <a routerLink="/publish">
                <span class="tooltip text-black"><span class="textroted">Welcome willisom Chandler to Roc Nation Sport </span></span>
                  
                <div class="box-face">
              
                  <div class="box-text"> <img class="image11 " src="./../../assets/image/bloguser3.jpg"></div>
                </div>
</a>
              </div>
              <div class="box">
                <a routerLink="/publish">
                <span class="tooltip text-black"><span class="textroted">Welcome willisom Chandler to Roc Nation Sport </span></span>
                  
                <div class="box-face">
              
                  <div class="box-text"> <img class="image11 " src="./../../assets/image/bloguser3.jpg"></div>
                </div>
</a>
              </div>
            </div>
            <div class="v-boxes second deshbord">
              <div class="box">
                <a routerLink="/publish">
                <span class="tooltip text-black"><span class="textroted">Welcome willisom Chandler to Roc Nation Sport </span></span>
                  
                <div class="box-face">
              
                  <div class="box-text"> <img class="image11 " src="./../../assets/image/bloguser3.jpg"></div>
                </div>
</a>
              </div>
              <div class="box">
                <a routerLink="/publish">
                <span class="tooltip text-black"><span class="textroted">Welcome willisom Chandler to Roc Nation Sport </span></span>
                  
                <div class="box-face">
              
                  <div class="box-text"> <img class="image11 " src="./../../assets/image/bloguser3.jpg"></div>
                </div>
</a>
              </div>
              <div class="box">
                <a routerLink="/publish">
                <span class="tooltip text-black"><span class="textroted">Welcome willisom Chandler to Roc Nation Sport </span></span>
                  
                <div class="box-face">
              
                  <div class="box-text"> <img class="image11 " src="./../../assets/image/bloguser3.jpg"></div>
                </div>
</a>
              </div>
            </div>
            <div class="v-boxes deshbord">
              <div class="box">
                <a routerLink="/publish">
                <span class="tooltip text-black"><span class="textroted">Welcome willisom Chandler to Roc Nation Sport </span></span>
                  
                <div class="box-face">
              
                  <div class="box-text"> <img class="image11 " src="./../../assets/image/bloguser3.jpg"></div>
                </div>
</a>
              </div>
              <div class="box">
                <a routerLink="/publish">
                <span class="tooltip text-black"><span class="textroted">Welcome willisom Chandler to Roc Nation Sport </span></span>
                  
                <div class="box-face">
              
                  <div class="box-text"> <img class="image11 " src="./../../assets/image/bloguser3.jpg"></div>
                </div>
</a>
              </div>
              <div class="box">
                <a routerLink="/publish">
                <span class="tooltip text-black"><span class="textroted">Welcome willisom Chandler to Roc Nation Sport </span></span>
                  
                <div class="box-face">
              
                  <div class="box-text"> <img class="image11 " src="./../../assets/image/bloguser3.jpg"></div>
                </div>
</a>
              </div>
              <div class="box">
                <a routerLink="/publish">
                <span class="tooltip text-black"><span class="textroted">Welcome willisom Chandler to Roc Nation Sport </span></span>
                  
                <div class="box-face">
              
                  <div class="box-text"> <img class="image11 " src="./../../assets/image/bloguser3.jpg"></div>
                </div>
</a>
              </div>
            </div>
            <div class="v-boxes second">
              <div class="box">
                <a routerLink="/publish">
                <span class="tooltip text-black"><span class="textroted">Welcome willisom Chandler to Roc Nation Sport </span></span>
                  
                <div class="box-face">
              
                  <div class="box-text"> <img class="image11 " src="./../../assets/image/bloguser3.jpg"></div>
                </div>
</a>
              </div>
              <div class="box">
                <a routerLink="/publish">
                <span class="tooltip text-black"><span class="textroted">Welcome willisom Chandler to Roc Nation Sport </span></span>
                  
                <div class="box-face">
              
                  <div class="box-text"> <img class="image11 " src="./../../assets/image/bloguser3.jpg"></div>
                </div>
</a>
              </div>
              <div class="box">
                <a routerLink="/publish">
                <span class="tooltip text-black"><span class="textroted">Welcome willisom Chandler to Roc Nation Sport </span></span>
                  
                <div class="box-face">
              
                  <div class="box-text"> <img class="image11 " src="./../../assets/image/bloguser3.jpg"></div>
                </div>
</a>
              </div>
            </div>
            <div class="v-boxes deshbord">
              <div class="box">
                <a routerLink="/publish">
                <span class="tooltip text-black"><span class="textroted">Welcome willisom Chandler to Roc Nation Sport </span></span>
                  
                <div class="box-face">
              
                  <div class="box-text"> <img class="image11 " src="./../../assets/image/bloguser3.jpg"></div>
                </div>
</a>
              </div>
              <div class="box">
                <a routerLink="/publish">
                <span class="tooltip text-black"><span class="textroted">Welcome willisom Chandler to Roc Nation Sport </span></span>
                  
                <div class="box-face">
              
                  <div class="box-text"> <img class="image11 " src="./../../assets/image/bloguser3.jpg"></div>
                </div>
</a>
              </div>
              <div class="box">
                <a routerLink="/publish">
                <span class="tooltip text-black"><span class="textroted">Welcome willisom Chandler to Roc Nation Sport </span></span>
                  
                <div class="box-face">
              
                  <div class="box-text"> <img class="image11 " src="./../../assets/image/bloguser3.jpg"></div>
                </div>
</a>
              </div>
              <div class="box">
                <a routerLink="/publish">
                <span class="tooltip text-black"><span class="textroted">Welcome willisom Chandler to Roc Nation Sport </span></span>
                  
                <div class="box-face">
              
                  <div class="box-text"> <img class="image11 " src="./../../assets/image/bloguser3.jpg"></div>
                </div>
</a>
              </div>
            </div>
            <div class="v-boxes second deshbord">
              <div class="box">
                <a routerLink="/publish">
                <span class="tooltip text-black"><span class="textroted">Welcome willisom Chandler to Roc Nation Sport </span></span>
                  
                <div class="box-face">
              
                  <div class="box-text"> <img class="image11 " src="./../../assets/image/bloguser3.jpg"></div>
                </div>
</a>
              </div>
              <div class="box">
                <a routerLink="/publish">
                <span class="tooltip text-black"><span class="textroted">Welcome willisom Chandler to Roc Nation Sport </span></span>
                  
                <div class="box-face">
              
                  <div class="box-text"> <img class="image11 " src="./../../assets/image/bloguser3.jpg"></div>
                </div>
</a>
              </div>
              <div class="box">
                <a routerLink="/publish">
                <span class="tooltip text-black"><span class="textroted">Welcome willisom Chandler to Roc Nation Sport </span></span>
                  
                <div class="box-face">
              
                  <div class="box-text"> <img class="image11 " src="./../../assets/image/bloguser3.jpg"></div>
                </div>
</a>
              </div>
            </div>
            <div class="v-boxes deshbord">
              <div class="box">
                <a routerLink="/publish">
                <span class="tooltip text-black"><span class="textroted">Welcome willisom Chandler to Roc Nation Sport </span></span>
                  
                <div class="box-face">
              
                  <div class="box-text"> <img class="image11 " src="./../../assets/image/bloguser3.jpg"></div>
                </div>
</a>
              </div>
              <div class="box">
                <a routerLink="/publish">
                <span class="tooltip text-black"><span class="textroted">Welcome willisom Chandler to Roc Nation Sport </span></span>
                  
                <div class="box-face">
              
                  <div class="box-text"> <img class="image11 " src="./../../assets/image/bloguser3.jpg"></div>
                </div>
</a>
              </div>
              <div class="box">
                <a routerLink="/publish">
                <span class="tooltip text-black"><span class="textroted">Welcome willisom Chandler to Roc Nation Sport </span></span>
                  
                <div class="box-face">
              
                  <div class="box-text"> <img class="image11 " src="./../../assets/image/bloguser3.jpg"></div>
                </div>
</a>
              </div>
              <div class="box">
                <a routerLink="/publish">
                <span class="tooltip text-black"><span class="textroted">Welcome willisom Chandler to Roc Nation Sport </span></span>
                  
                <div class="box-face">
              
                  <div class="box-text"> <img class="image11 " src="./../../assets/image/bloguser3.jpg"></div>
                </div>
</a>
              </div>
            </div>
            <div class="v-boxes second deshbord">
              <div class="box">
                <a routerLink="/publish">
                <span class="tooltip text-black"><span class="textroted">Welcome willisom Chandler to Roc Nation Sport </span></span>
                  
                <div class="box-face">
              
                  <div class="box-text"> <img class="image11 " src="./../../assets/image/bloguser3.jpg"></div>
                </div>
</a>
              </div>
              <div class="box">
                <a routerLink="/publish">
                <span class="tooltip text-black"><span class="textroted">Welcome willisom Chandler to Roc Nation Sport </span></span>
                  
                <div class="box-face">
              
                  <div class="box-text"> <img class="image11 " src="./../../assets/image/bloguser3.jpg"></div>
                </div>
</a>
              </div>
              <div class="box">
                <a routerLink="/publish">
                <span class="tooltip text-black"><span class="textroted">Welcome willisom Chandler to Roc Nation Sport </span></span>
                  
                <div class="box-face">
              
                  <div class="box-text"> <img class="image11 " src="./../../assets/image/bloguser3.jpg"></div>
                </div>
</a>
              </div>
            </div>

            <div class="v-boxes deshbord">
              <div class="box">
                <a routerLink="/publish">
                <span class="tooltip text-black"><span class="textroted">Welcome willisom Chandler to Roc Nation Sport </span></span>
                  
                <div class="box-face">
              
                  <div class="box-text"> <img class="image11 " src="./../../assets/image/bloguser3.jpg"></div>
                </div>
</a>
              </div>
              <div class="box">
                <a routerLink="/publish">
                <span class="tooltip text-black"><span class="textroted">Welcome willisom Chandler to Roc Nation Sport </span></span>
                  
                <div class="box-face">
              
                  <div class="box-text"> <img class="image11 " src="./../../assets/image/bloguser3.jpg"></div>
                </div>
</a>
              </div>
              <div class="box">
                <a routerLink="/publish">
                <span class="tooltip text-black"><span class="textroted">Welcome willisom Chandler to Roc Nation Sport </span></span>
                  
                <div class="box-face">
              
                  <div class="box-text"> <img class="image11 " src="./../../assets/image/bloguser3.jpg"></div>
                </div>
</a>
              </div>
              <div class="box">
                <a routerLink="/publish">
                <span class="tooltip text-black"><span class="textroted">Welcome willisom Chandler to Roc Nation Sport </span></span>
                  
                <div class="box-face">
              
                  <div class="box-text"> <img class="image11 " src="./../../assets/image/bloguser3.jpg"></div>
                </div>
</a>
              </div>
            </div>





          </div>
        </section>


       
      </div>

    </div>


    <a class=" carousel-control " href="#myCarousel" data-slide="prev">
      <span class="fa fa-arrow-left arr "></span>
      <!-- <div class="arricon">
          <span class="sr-only">Previous</span>
      </div> -->
    </a>
    <a class=" float-right carousel-control" href="#myCarousel" data-slide="next">
      <span class="fa fa-arrow-right  arr1"></span>
      <!-- <div class="arricon1">
          <span class="sr-only">Next</span>
          </div> -->
    </a>
  </div>

</div>


<div #target>
  <div class=" container cc-container">
    <div class="row">

      <div class="col-md-12 bgmap">
     
        <div id="regions_div" class="" *ngIf="showMap" (click)="itemSelected($event)"></div>
        <div class="toggle" *ngIf="showMap">
          <a routerLink="/"><img src="./../../assets/image/Component 7 – 18.svg" id="toggle_map"
              (click)="changeStatus(imgTarget)" class="tilee" alt="..."></a>


          <div id="mySidenav" class="sidenav">
            <a href="javascript:void(0)" class="closebtn" (click)="closeNav()"><i
                class='fas fa-angle-right  font-weight-none close-nav'></i></a>

            <div class="close-1">
              <h3 class="py-2">Add your Testimonial</h3>
              <div class="input-group">
                <input type="text" class="input-area" required id="inputField" />
                <label for="inputField" class="label">First Name</label>
              </div> <br>
              <div class="input-group">
                <input type="text" class="input-area" required id="inputField" />
                <label for="inputField" class="label">Last Name</label>
              </div><br>
              <div class="input-group">
                <input type="text" class="input-area" required id="inputField" />
                <label for="inputField" class="label">Email</label>
              </div><br>
              <div class="input-group">
                <input type="text" class="input-area" required id="inputField" />
                <label for="inputField" class="label">country</label>
              </div><br>
              <div class="input-group">
                <input type="text" class="input-area" required id="inputField" />
                <label for="inputField" class="label">City</label>
              </div><br>
              <div class="input-group">
                <textarea type="text" class="input-area" required id="inputField"></textarea>
                <label for="inputField" class="label">Text Your Massage</label>
              </div><br>
              <div class="testimonial ">
                <h4 class=" py-3 text-white text-center">Add Testimonial</h4>
              </div>
            </div>
          </div>
          <span class="float-right text-white" (click)="openNav()"><i
              class='fas fa-angle-left fa-3x display-3 font-weight-none open-nav' id="open"></i>
            <h4 class="add-test">Add Testimonial</h4>
          </span>

        </div>
      </div>
    </div>
  </div>
</div>







<br><br><br>



<div class="container cc-containerf  ">
  <div class="row">
    <div class="col-md-12">
      <ul class="list-class text-white">
        <a routerLink="/more-author"> <li>All</li></a>
        <a routerLink="/more-author"><li>Style</li></a>
        <a routerLink="/more-author"><li>Style</li></a>
        <a routerLink="/more-author"><li>Leisure</li></a>
        <a routerLink="/more-author"><li>Art & Design</li></a>

        <a routerLink="/more-author"><li>Technology</li></a>
        <a routerLink="/more-author"><li>Technology</li></a>
        <a routerLink="/more-author"> <li>Music</li></a>
        <a routerLink="/more-author"><li>Sports</li></a>
        <a routerLink="/more-author"><li>RoundTable</li></a>
        <a routerLink="/more-author"><li>Decoded</li></a>
      </ul>
    </div>
  </div>
  <div class="row">
    <div class="col-md-3"></div>
    <div class="col-md-1">
      <h2 class="text-white mob_t ">Parable <br> Symptoms</h2>
    </div>
    <div class="col-md-3 text-white">
      <h2 class=" display-4 mob_t">
        0&nbsp;2&nbsp;.&nbsp;0&nbsp;2&nbsp;.&nbsp;2&nbsp;0&nbsp;2&nbsp;0</h2>
    </div>
    <div class="col-md-2 text-white">
      <h2 class=" display-4 mob_t">
        0&nbsp;2&nbsp;.&nbsp;0&nbsp;2&nbsp;.&nbsp;2&nbsp;0&nbsp;2&nbsp;0</h2>
    </div>
    <div class="col-md-3"></div>
  </div>

  <div class="row">
    <div class="col-md-12">
      <h5 class="text-white text-center">&copy; 2020 Parable Symptom. All right reserved Terms of use privacy
        policy</h5>
    </div>

  </div>
</div>