<div class="container123">
    <div class="container   ">
        <div class="row py-4 pb-2 line ">
            <div class="col-md-3">
                <a routerLink="/more-author"> <h1 class="text-white country  ">Brazil</h1></a> 
            </div> 
            <div class="col-md-4 float-right">
    
                <form class="example" action="/action_page.php">
                    <input type="text" class="text-white" placeholder="Search.." name="search2">
    
                </form>
            </div>
            <div class="col-md-1">
                 <img src="./../../assets/image/City.svg" class="city" alt="...">
            </div>
            <div class="col-md-3">
              
             
                <div class="dropdown">
                    <button class="btn list1 w-100 " data-toggle="dropdown"><b class="float-left">All</b> <img class=" float-right" src="./../../assets/image/Icon feather-filter.svg"> </button>
                   
                    <div class="dropdown-menu">
                        <ul class="texx">
                            <form class="example" action="/action_page.php">
                                <input type="text" class="text-white w-100" placeholder="Search.." name="search2">
                            </form>
                            <li class="text-white pt-2">&nbsp;Sao Paulo</li><br>
                            <li class="text-white pt-2">&nbsp;Rio de Janeiro</li><br>
                            <li class="text-white pt-2">&nbsp;Brasilia</li><br>
                            <li class="text-white pt-2">&nbsp;Brasilia</li><br>
                            <li class="text-white pt-2">&nbsp;Brasilia</li><br>
                            <li class="text-white pt-2">&nbsp;Rio de Janeiro</li><br>
                            <li class="text-white pt-2">&nbsp;Brasilia</li><br>
                            <li class="text-white pt-2">&nbsp;Rio de Janeiro</li><br>
                        </ul>
                    </div>
                  </div>
            </div>
            <div class="col-md-1 pt-0">
                <a routerLink="/"> <img src="./../../assets/image/Component 7 – 18.svg" class="tilee" alt="..."></a>
               </div>
        </div>
    
        <div class="row py-3 ">
            <div class="col-md-2">
                <a routerLink="/country">  <h1 class=" list1   ">Blog List</h1></a>
    
            </div>
            <div class="col-md-10">
    
                <h1 class=" list active ">User List</h1>
            </div>
        </div>
        <div class="row py-3 ">
            <div class="col-md-4">
    
    
            </div>
            <div class="col-md-8">
    
    
            </div>
        </div>
        <div class="container">
            <div class="card-deck">
               
                <div class="card mb-4">
                    <a routerLink="/profile">
                    <img src="./../../assets/image/bloguser5.jpg" class="card-img-top" alt="...">
                    <div class="card-body">
                        <h3 class="card-title text-white pb-3">Colin Delgado</h3>
                        <img class="micon image " src="./../../assets/image/Group 372.svg">&nbsp;&nbsp;
                        <img class="micon" src="./../../assets/image/Group 371.svg">&nbsp;&nbsp;
                        <img class="micon" src="./../../assets/image/Group 370.svg">&nbsp;&nbsp;
                        <img class="micon" src="./../../assets/image/Group 425.svg">&nbsp;&nbsp;
                        <img class="micon" src="./../../assets/image/Group 369.svg">&nbsp;&nbsp;
                    </div>
                    <div class="overlay">
                    </div>  </a>
                </div>
              
                <div class="card mb-4">
                    <a routerLink="/profile">
                    <img src="./../../assets/image/bloguser5.jpg" class="card-img-top" alt="...">
                    <div class="card-body">
                        <h3 class="card-title text-white pb-3">Colin Delgado</h3>
                        <img class="micon image " src="./../../assets/image/Group 372.svg">&nbsp;&nbsp;
                        <img class="micon" src="./../../assets/image/Group 371.svg">&nbsp;&nbsp;
                        <img class="micon" src="./../../assets/image/Group 370.svg">&nbsp;&nbsp;
                        <img class="micon" src="./../../assets/image/Group 425.svg">&nbsp;&nbsp;
                        <img class="micon" src="./../../assets/image/Group 369.svg">&nbsp;&nbsp;
                    </div>
                    <div class="overlay">
                    </div></a>
                </div>
                <div class="w-100 d-none d-sm-block d-md-none"><!-- wrap every 2 on sm--></div>
                <div class="card mb-4">
                    <a routerLink="/profile">
                    <img src="./../../assets/image/bloguser5.jpg" class="card-img-top" alt="...">
                    <div class="card-body">
                        <h3 class="card-title text-white pb-3">Colin Delgado</h3>
                        <img class="micon image " src="./../../assets/image/Group 372.svg">&nbsp;&nbsp;
                        <img class="micon" src="./../../assets/image/Group 371.svg">&nbsp;&nbsp;
                        <img class="micon" src="./../../assets/image/Group 370.svg">&nbsp;&nbsp;
                        <img class="micon" src="./../../assets/image/Group 425.svg">&nbsp;&nbsp;
                        <img class="micon" src="./../../assets/image/Group 369.svg">&nbsp;&nbsp;
                    </div>
                    <div class="overlay">
                    </div></a>
                </div>
                <div class="w-100 d-none d-md-block d-lg-none"><!-- wrap every 3 on md--></div>
                <div class="card mb-4">
                    <a routerLink="/profile">
                    <img src="./../../assets/image/bloguser5.jpg" class="card-img-top" alt="...">
                    <div class="card-body">
                        <h3 class="card-title text-white pb-3">Colin Delgado</h3>
                        <img class="micon image " src="./../../assets/image/Group 372.svg">&nbsp;&nbsp;
                        <img class="micon" src="./../../assets/image/Group 371.svg">&nbsp;&nbsp;
                        <img class="micon" src="./../../assets/image/Group 370.svg">&nbsp;&nbsp;
                        <img class="micon" src="./../../assets/image/Group 425.svg">&nbsp;&nbsp;
                        <img class="micon" src="./../../assets/image/Group 369.svg">&nbsp;&nbsp;
                    </div>
                    <div class="overlay">
                    </div></a>
                </div>
                <div class="w-100 d-none d-sm-block d-md-none"><!-- wrap every 2 on sm--></div>
                 <div class="w-100 d-none d-lg-block d-xl-none"><!-- wrap every 4 on lg--></div>
                 <div class="card mb-4">
                    <a routerLink="/profile">
                    <img src="./../../assets/image/bloguser5.jpg" class="card-img-top" alt="...">
                    <div class="card-body">
                        <h3 class="card-title text-white pb-3">Colin Delgado</h3>
                        <img class="micon image " src="./../../assets/image/Group 372.svg">&nbsp;&nbsp;
                        <img class="micon" src="./../../assets/image/Group 371.svg">&nbsp;&nbsp;
                        <img class="micon" src="./../../assets/image/Group 370.svg">&nbsp;&nbsp;
                        <img class="micon" src="./../../assets/image/Group 425.svg">&nbsp;&nbsp;
                        <img class="micon" src="./../../assets/image/Group 369.svg">&nbsp;&nbsp;
                    </div></a>
                    <div class="overlay">
                    </div>
                </div>
                <div class="w-100 d-none d-xl-block"><!-- wrap every 5 on xl--></div>
                <div class="card mb-4">
                    <a routerLink="/profile">
                    <img src="./../../assets/image/bloguser5.jpg" class="card-img-top" alt="...">
                    <div class="card-body">
                        <h3 class="card-title text-white pb-3">Colin Delgado</h3>
                        <img class="micon image " src="./../../assets/image/Group 372.svg">&nbsp;&nbsp;
                        <img class="micon" src="./../../assets/image/Group 371.svg">&nbsp;&nbsp;
                        <img class="micon" src="./../../assets/image/Group 370.svg">&nbsp;&nbsp;
                        <img class="micon" src="./../../assets/image/Group 425.svg">&nbsp;&nbsp;
                        <img class="micon" src="./../../assets/image/Group 369.svg">&nbsp;&nbsp;
                    </div>
                    <div class="overlay">
                    </div></a>
                </div>
                <div class="w-100 d-none d-sm-block d-md-none"><!-- wrap every 2 on sm--></div>
                <div class="w-100 d-none d-md-block d-lg-none"><!-- wrap every 3 on md--></div>
                <div class="card mb-4">
                    <a routerLink="/profile">
                    <img src="./../../assets/image/bloguser5.jpg" class="card-img-top" alt="...">
                    <div class="card-body">
                        <h3 class="card-title text-white pb-3">Colin Delgado</h3>
                        <img class="micon image " src="./../../assets/image/Group 372.svg">&nbsp;&nbsp;
                        <img class="micon" src="./../../assets/image/Group 371.svg">&nbsp;&nbsp;
                        <img class="micon" src="./../../assets/image/Group 370.svg">&nbsp;&nbsp;
                        <img class="micon" src="./../../assets/image/Group 425.svg">&nbsp;&nbsp;
                        <img class="micon" src="./../../assets/image/Group 369.svg">&nbsp;&nbsp;
                    </div>
                    <div class="overlay">
                    </div></a>
                </div>
                <div class="card mb-4">
                    <a routerLink="/profile">
                    <img src="./../../assets/image/bloguser5.jpg" class="card-img-top" alt="...">
                    <div class="card-body">
                        <h3 class="card-title text-white pb-3">Colin Delgado</h3>
                        <img class="micon image " src="./../../assets/image/Group 372.svg">&nbsp;&nbsp;
                        <img class="micon" src="./../../assets/image/Group 371.svg">&nbsp;&nbsp;
                        <img class="micon" src="./../../assets/image/Group 370.svg">&nbsp;&nbsp;
                        <img class="micon" src="./../../assets/image/Group 425.svg">&nbsp;&nbsp;
                        <img class="micon" src="./../../assets/image/Group 369.svg">&nbsp;&nbsp;
                    </div>
                    <div class="overlay">
                    </div></a>
                </div>
                <div class="w-100 d-none d-sm-block d-md-none"><!-- wrap every 2 on sm--></div>
                <div class="w-100 d-none d-lg-block d-xl-none"><!-- wrap every 4 on lg--></div>
                <div class="card mb-4">
                    <a routerLink="/profile">
                    <img src="./../../assets/image/bloguser5.jpg" class="card-img-top" alt="...">
                    <div class="card-body">
                        <h3 class="card-title text-white pb-3">Colin Delgado</h3>
                        <img class="micon image " src="./../../assets/image/Group 372.svg">&nbsp;&nbsp;
                        <img class="micon" src="./../../assets/image/Group 371.svg">&nbsp;&nbsp;
                        <img class="micon" src="./../../assets/image/Group 370.svg">&nbsp;&nbsp;
                        <img class="micon" src="./../../assets/image/Group 425.svg">&nbsp;&nbsp;
                        <img class="micon" src="./../../assets/image/Group 369.svg">&nbsp;&nbsp;
                    </div>
                    <div class="overlay">
                    </div></a>
                </div>
                <div class="w-100 d-none d-md-block d-lg-none"><!-- wrap every 3 on md--></div>
                <div class="card mb-4">
                    <a routerLink="/profile">
                    <img src="./../../assets/image/bloguser5.jpg" class="card-img-top" alt="...">
                    <div class="card-body">
                        <h3 class="card-title text-white pb-3">Colin Delgado</h3>
                        <img class="micon image " src="./../../assets/image/Group 372.svg">&nbsp;&nbsp;
                        <img class="micon" src="./../../assets/image/Group 371.svg">&nbsp;&nbsp;
                        <img class="micon" src="./../../assets/image/Group 370.svg">&nbsp;&nbsp;
                        <img class="micon" src="./../../assets/image/Group 425.svg">&nbsp;&nbsp;
                        <img class="micon" src="./../../assets/image/Group 369.svg">&nbsp;&nbsp;
                    </div>
                    <div class="overlay">
                    </div></a>
                </div>
            </div>
             <div class="card-deck">
                <div class="card mb-4">
                    <a routerLink="/profile">
                    <img src="./../../assets/image/bloguser5.jpg" class="card-img-top" alt="...">
                    <div class="card-body">
                        <h3 class="card-title text-white pb-3">Colin Delgado</h3>
                        <img class="micon image " src="./../../assets/image/Group 372.svg">&nbsp;&nbsp;
                        <img class="micon" src="./../../assets/image/Group 371.svg">&nbsp;&nbsp;
                        <img class="micon" src="./../../assets/image/Group 370.svg">&nbsp;&nbsp;
                        <img class="micon" src="./../../assets/image/Group 425.svg">&nbsp;&nbsp;
                        <img class="micon" src="./../../assets/image/Group 369.svg">&nbsp;&nbsp;
                    </div>
                    <div class="overlay">
                    </div></a>
                </div>
                <div class="card mb-4">
                    <a routerLink="/profile">
                    <img src="./../../assets/image/bloguser5.jpg" class="card-img-top" alt="...">
                    <div class="card-body">
                        <h3 class="card-title text-white pb-3">Colin Delgado</h3>
                        <img class="micon image " src="./../../assets/image/Group 372.svg">&nbsp;&nbsp;
                        <img class="micon" src="./../../assets/image/Group 371.svg">&nbsp;&nbsp;
                        <img class="micon" src="./../../assets/image/Group 370.svg">&nbsp;&nbsp;
                        <img class="micon" src="./../../assets/image/Group 425.svg">&nbsp;&nbsp;
                        <img class="micon" src="./../../assets/image/Group 369.svg">&nbsp;&nbsp;
                    </div>
                    <div class="overlay">
                    </div></a>
                </div>
                <div class="w-100 d-none d-sm-block d-md-none"><!-- wrap every 2 on sm--></div>
                <div class="card mb-4">
                    <a routerLink="/profile">
                    <img src="./../../assets/image/bloguser5.jpg" class="card-img-top" alt="...">
                    <div class="card-body">
                        <h3 class="card-title text-white pb-3">Colin Delgado</h3>
                        <img class="micon image " src="./../../assets/image/Group 372.svg">&nbsp;&nbsp;
                        <img class="micon" src="./../../assets/image/Group 371.svg">&nbsp;&nbsp;
                        <img class="micon" src="./../../assets/image/Group 370.svg">&nbsp;&nbsp;
                        <img class="micon" src="./../../assets/image/Group 425.svg">&nbsp;&nbsp;
                        <img class="micon" src="./../../assets/image/Group 369.svg">&nbsp;&nbsp;
                    </div>
                    <div class="overlay">
                    </div></a>
                </div>
                <div class="w-100 d-none d-md-block d-lg-none"><!-- wrap every 3 on md--></div>
                <div class="card mb-4">
                    <a routerLink="/profile">
                    <img src="./../../assets/image/bloguser5.jpg" class="card-img-top" alt="...">
                    <div class="card-body">
                        <h3 class="card-title text-white pb-3">Colin Delgado</h3>
                        <img class="micon image " src="./../../assets/image/Group 372.svg">&nbsp;&nbsp;
                        <img class="micon" src="./../../assets/image/Group 371.svg">&nbsp;&nbsp;
                        <img class="micon" src="./../../assets/image/Group 370.svg">&nbsp;&nbsp;
                        <img class="micon" src="./../../assets/image/Group 425.svg">&nbsp;&nbsp;
                        <img class="micon" src="./../../assets/image/Group 369.svg">&nbsp;&nbsp;
                    </div>
                    <div class="overlay">
                    </div></a>
                </div>
                <div class="w-100 d-none d-sm-block d-md-none"><!-- wrap every 2 on sm--></div>
                 <div class="w-100 d-none d-lg-block d-xl-none"><!-- wrap every 4 on lg--></div>
                 <div class="card mb-4">
                    <a routerLink="/profile">
                    <img src="./../../assets/image/bloguser5.jpg" class="card-img-top" alt="...">
                    <div class="card-body">
                        <h3 class="card-title text-white pb-3">Colin Delgado</h3>
                        <img class="micon image " src="./../../assets/image/Group 372.svg">&nbsp;&nbsp;
                        <img class="micon" src="./../../assets/image/Group 371.svg">&nbsp;&nbsp;
                        <img class="micon" src="./../../assets/image/Group 370.svg">&nbsp;&nbsp;
                        <img class="micon" src="./../../assets/image/Group 425.svg">&nbsp;&nbsp;
                        <img class="micon" src="./../../assets/image/Group 369.svg">&nbsp;&nbsp;
                    </div>
                    <div class="overlay">
                    </div></a>
                </div>
                <div class="w-100 d-none d-xl-block"><!-- wrap every 5 on xl--></div>
                <div class="card mb-4">
                    <a routerLink="/profile">
                    <img src="./../../assets/image/bloguser5.jpg" class="card-img-top" alt="...">
                    <div class="card-body">
                        <h3 class="card-title text-white pb-3">Colin Delgado</h3>
                        <img class="micon image " src="./../../assets/image/Group 372.svg">&nbsp;&nbsp;
                        <img class="micon" src="./../../assets/image/Group 371.svg">&nbsp;&nbsp;
                        <img class="micon" src="./../../assets/image/Group 370.svg">&nbsp;&nbsp;
                        <img class="micon" src="./../../assets/image/Group 425.svg">&nbsp;&nbsp;
                        <img class="micon" src="./../../assets/image/Group 369.svg">&nbsp;&nbsp;
                    </div>
                    <div class="overlay">
                    </div></a>
                </div>
                <div class="w-100 d-none d-sm-block d-md-none"><!-- wrap every 2 on sm--></div>
                <div class="w-100 d-none d-md-block d-lg-none"><!-- wrap every 3 on md--></div>
                <div class="card mb-4">
                    <a routerLink="/profile">
                    <img src="./../../assets/image/bloguser5.jpg" class="card-img-top" alt="...">
                    <div class="card-body">
                        <h3 class="card-title text-white pb-3">Colin Delgado</h3>
                        <img class="micon image " src="./../../assets/image/Group 372.svg">&nbsp;&nbsp;
                        <img class="micon" src="./../../assets/image/Group 371.svg">&nbsp;&nbsp;
                        <img class="micon" src="./../../assets/image/Group 370.svg">&nbsp;&nbsp;
                        <img class="micon" src="./../../assets/image/Group 425.svg">&nbsp;&nbsp;
                        <img class="micon" src="./../../assets/image/Group 369.svg">&nbsp;&nbsp;
                    </div>
                    <div class="overlay">
                    </div></a>
                </div>
                <div class="card mb-4">
                    <a routerLink="/profile">
                    <img src="./../../assets/image/bloguser5.jpg" class="card-img-top" alt="...">
                    <div class="card-body">
                        <h3 class="card-title text-white pb-3">Colin Delgado</h3>
                        <img class="micon image " src="./../../assets/image/Group 372.svg">&nbsp;&nbsp;
                        <img class="micon" src="./../../assets/image/Group 371.svg">&nbsp;&nbsp;
                        <img class="micon" src="./../../assets/image/Group 370.svg">&nbsp;&nbsp;
                        <img class="micon" src="./../../assets/image/Group 425.svg">&nbsp;&nbsp;
                        <img class="micon" src="./../../assets/image/Group 369.svg">&nbsp;&nbsp;
                    </div>
                    <div class="overlay">
                    </div></a>
                </div>
                <div class="w-100 d-none d-sm-block d-md-none"><!-- wrap every 2 on sm--></div>
                <div class="w-100 d-none d-lg-block d-xl-none"><!-- wrap every 4 on lg--></div>
                <div class="card mb-4">
                    <a routerLink="/profile">
                    <img src="./../../assets/image/bloguser5.jpg" class="card-img-top" alt="...">
                    <div class="card-body">
                        <h3 class="card-title text-white pb-3">Colin Delgado</h3>
                        <img class="micon image " src="./../../assets/image/Group 372.svg">&nbsp;&nbsp;
                        <img class="micon" src="./../../assets/image/Group 371.svg">&nbsp;&nbsp;
                        <img class="micon" src="./../../assets/image/Group 370.svg">&nbsp;&nbsp;
                        <img class="micon" src="./../../assets/image/Group 425.svg">&nbsp;&nbsp;
                        <img class="micon" src="./../../assets/image/Group 369.svg">&nbsp;&nbsp;
                    </div>
                    <div class="overlay">
                    </div></a>
                </div>
                <div class="w-100 d-none d-md-block d-lg-none"><!-- wrap every 3 on md--></div>
                <div class="card mb-4">
                    <a routerLink="/profile">
                    <img src="./../../assets/image/bloguser5.jpg" class="card-img-top" alt="...">
                    <div class="card-body">
                        <h3 class="card-title text-white pb-3">Colin Delgado</h3>
                        <img class="micon image " src="./../../assets/image/Group 372.svg">&nbsp;&nbsp;
                        <img class="micon" src="./../../assets/image/Group 371.svg">&nbsp;&nbsp;
                        <img class="micon" src="./../../assets/image/Group 370.svg">&nbsp;&nbsp;
                        <img class="micon" src="./../../assets/image/Group 425.svg">&nbsp;&nbsp;
                        <img class="micon" src="./../../assets/image/Group 369.svg">&nbsp;&nbsp;
                    </div>
                    <div class="overlay">
                    </div></a>
                </div>
            </div>
        </div>

    </div>
</div>


<div class="container cc-container  ">
   
   
    <div class="row">
        <div class="col-md-3"></div>
        <div class="col-md-1">
            <a routerLink="/"> <h2 class="text-white mob_t ">Parable <br> Symptoms</h2></a>
        </div>
        <div class="col-md-3 text-white">
            <h2 class=" display-4 mob_t">
                0&nbsp;2&nbsp;.&nbsp;0&nbsp;2&nbsp;.&nbsp;2&nbsp;0&nbsp;2&nbsp;0</h2>
        </div>
        <div class="col-md-2 text-white">
          <h2 class=" display-4 mob_t">
              0&nbsp;2&nbsp;.&nbsp;0&nbsp;2&nbsp;.&nbsp;2&nbsp;0&nbsp;2&nbsp;0</h2>
      </div>
        <div class="col-md-3"></div>
    </div>
    <div class="row">
        <div class="col-md-12">
            <h5 class="text-white text-center">&copy; 2020 Parable Symptom. All right reserved Terms of use privacy
                policy</h5>
        </div>

    </div>
</div>






























