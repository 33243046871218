import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-more-author',
  templateUrl: './more-author.component.html',
  styleUrls: ['./more-author.component.scss']
})
export class MoreAuthorComponent implements OnInit {

  constructor() { }

  ngOnInit(): void {
  }

}
